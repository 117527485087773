<template>
  <section class="promo">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6">
          <div class="promo__content">
            <h1 class="title title--big title--white promo__title">
              Buy Electricity Units<br />Anywhere in Nigeria!
            </h1>
            <form
              action="#"
              id="subscribe-top"
              class="form join-us justify-content-center align-items-center"
              autocomplete="off"
            >
              <p class="small mb-1" v-if="!isUser">
                Enter Phone Number to buy Electricity
              </p>
              <div v-else>
                <h5
                  class="login-title fw-600 text-white font-2 text-center mb_5"
                >
                  LOGIN
                </h5>
                <p class="small mb-1">Enter Your Login Details To Buy Units</p>
              </div>
              <input
                name="phone"
                class="form__input"
                type="text"
                v-model="phone_number"
                @keypress="$globalFunc.onlyNumber"
                placeholder="e.g 08123456789"
                maxlength="11"
                :disabled="phone_number"
                :class="[{ 'mb-1': !phone_number && !isUserLoggedIn }]"
                v-if="isUserLoggedIn"
              />
              <input
                name="phone"
                class="form__input"
                type="text"
                v-model="fields.phone"
                @keypress="$globalFunc.onlyNumber"
                placeholder="e.g 08123456789"
                maxlength="11"
                :class="[{ 'mb-1': !phone_number && !isUserLoggedIn }]"
                autofocus=""
                v-else
              />
              <div class="password-input w-70" v-if="isUser">
                <input
                  :type="[reveal ? 'text' : 'password']"
                  v-model="fields.password"
                  class="form__input mb-1"
                  placeholder="Password"
                />
                <i
                  :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']"
                  @click.prevent="revealPassword"
                ></i>
              </div>
              <p
                class="m-0 smaller text-white revert-phone mb-1 mt_5"
                v-if="phone_number && !isUserLoggedIn"
                @click.prevent="removePhoneNumber()"
              >
                Not your phone number?
              </p>
              <p
                class="m-0 smaller text-white revert-phone mb-1"
                v-if="isUser"
                @click.prevent="continueAsGuest()"
              >
                Continue as Guest?
              </p>
              <button
                class="btn btn-standard w-45"
                :class="[{ 'mt-1': isUserLoggedIn }]"
                @click.prevent="isUser ? handleLogin() : setPhoneNumber()"
                v-if="!isUserLoggedIn && (!phone_number || !isUser)"
              >
                {{ loading ? "Please wait..." : isUser ? "LOGIN" : "BUY NOW" }} &nbsp;
                <i class="fas fa-arrow-right" v-if="!loading"></i>
              </button>
              <button
                class="btn btn-standard w-45"
                :class="[{ 'mt-1': isUserLoggedIn }]"
                @click.prevent="handleDashboardLink()"
                v-if="isUserLoggedIn"
              >
                Back to Dashboard
              </button>
            </form>
            <div class="support-group">
              <p class="mb-1 text-center">Support Child Education</p>
              <p class="smaller text-center">
                Whenever you pay for electricity on BuyUnits.NG, a percentage of
                the amount goes towards ensuring that a less priviledged child
                in Nigeria gets access to education..
              </p>
            </div>
            <div
              class="
                talk-to-us
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <img
                v-lazy="talkIcon"
                alt="talk icon"
                class="img-fluid d-flex"
              />
              <p class="m-0 ml-1">
                Talk to us: <a :href="`tel: ${mobile}`">{{ mobile }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
import AuthHelpers from "@/functions/actions/AuthRequest";
export default {
  name: "StaticSection",
  computed: {
    ...mapState(["loading", "phone_number", "isUserLoggedIn"]),
    ...mapGetters(["isMerchant", "isMerchantActive"]),
  },
  components: {},
  mounted() {
    this.fields.phone = this.phone_number;
  },
  data() {
    return {
      mobile: process.env.VUE_APP_HELP_LINE,
      talkIcon: require("@/assets/buyunits/icons/iconoir_headset-help.svg"),
      fields: {
        phone: "",
        password: "",
      },
      isUser: false,
      reveal: false,
    };
  },
  methods: {
    revealPassword() {
      this.reveal = !this.reveal;
    },
    async setPhoneNumber() {
      if (this.validateRequest()) {
        const response = await GeneralHelpers.validUserPhone(this.fields.phone);
        if (response.status) {
          this.$store.dispatch("removeUserPhoneNumber");
          this.isUser = true;
        } else {
          this.$store.dispatch("setUserPhoneNumber", this.fields.phone);
          this.$router.push({ name: "GuestPurchase" });
        }
      }
    },
    handleDashboardLink() {
      if (this.isUserLoggedIn) {
        if (this.isMerchant) {
          this.$router.push({ name: "DashboardHome" });
        } else {
          this.$router.push({ name: "SellElectricity" });
        }
      }
    },
    async handleLogin() {
      if (this.validateLoginRequest()) {
        const response = await AuthHelpers.loginRequest(this.fields);
        if (response.status) {
          this.showAlert("Login success", response.message, "success");
          this.$store.dispatch("setUserPhoneNumber", this.fields.phone);
          if (this.isMerchant && this.isMerchantActive) {
            this.$router.push({ name: "DashboardHome" });
          } else {
            this.$router.push({ name: "SellElectricity" });
          }
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    continueAsGuest() {
      this.$store.dispatch("setUserPhoneNumber", this.fields.phone);
      this.isUser = false;
      this.$router.push({ name: "GuestPurchase" });
    },
    validateLoginRequest() {
      if (this.fields.phone) {
        if (this.fields.password) {
          return true;
        } else {
          this.showAlert(
            "Validation Error",
            "Kindly provide your password",
            "warn"
          );
        }
      } else {
        this.showAlert(
          "Validation Error",
          "Kindly provide your phone number",
          "warn"
        );
      }
    },
    validateRequest() {
      if (this.fields.phone) {
        if (this.fields.phone.length === 11) {
          return true;
        } else {
          this.showAlert(
            "Validation Error",
            "Phone number must be 11 digits",
            "warn"
          );
        }
      } else {
        this.showAlert(
          "Validation Error",
          "Kindly provide your phone numbesr",
          "error"
        );
      }
    },
    removePhoneNumber() {
      this.fields.phone = "";
      this.isUser = false;
      this.$store.dispatch("removeUserPhoneNumber");
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
.promo::after {
  /* background-image: url("assets/buyunits/slider-image.svg"); */
  background: #a8c0ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3f2b96, #a8c0ff);
  background: linear-gradient(to right, #3f2b96, #a8c0ff);
  background-size: cover;
  background-repeat: no-repeat;
}
</style>