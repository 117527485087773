<template>
  <div class="form-information-receipt">
    <div class="group">
      <p>Meter Number</p>
      <p>{{ getDetails && getDetails.meter_number }}</p>
    </div>
    <div class="group">
      <p>Transaction ID</p>
      <p>{{ getDetails && getDetails.transaction_id }}</p>
    </div>
    <div class="group">
      <p>Reference Number</p>
      <p>{{ getDetails && getDetails.payment_ref }}</p>
    </div>
    <div class="group">
      <p>Payment Method</p>
      <p>{{ getDetails && getDetails.payment_method && paymentMethod }}</p>
    </div>
    <div class="group">
      <p>Utility</p>
      <p>{{ getDetails && getDetails.disco }}</p>
    </div>
    <div class="group">
      <p>Token</p>
      <p class="token-copy">
        <img
          src="@/assets/buyunits/icons/bi_copy_icon.svg"
          alt="Copy icon"
          class="img-fluid"
          @click.prevent="copyToken()"
        />
        {{ getDetails && getDetails.token && hypenateToken }}
      </p>
    </div>
    <div class="group">
      <p>Units</p>
      <p>{{ getDetails && getDetails.units && parseFloat(getDetails.units).toFixed(2) }} Kwh</p>
    </div>
    <div class="group">
      <p>Amount</p>
      <p>&#8358; {{ getDetails && $globalFunc.currency(getDetails.amount) }}</p>
    </div>
    <div class="group" v-if="isMerchant && isMerchantActive">
      <p>Commission</p>
      <p class="small">
        &#8358; {{ getDetails && $globalFunc.currency(getDetails.commission) }}
      </p>
    </div>
    <div class="group">
      <p>Date and Time</p>
      <p>
        {{
          getDetails &&
          getDetails.created_at &&
          $globalFunc.makeDateFriendlyWithTime(getDetails.created_at)
        }}
      </p>
    </div>
    <h4 class="mt-1 mb-1">Customer Details</h4>
    <div class="group">
      <p>Customer Name</p>
      <p>{{ getDetails && getDetails.customer_name }}</p>
    </div>
    <div class="group">
      <p>Customer Address</p>
      <p>{{ getDetails && getDetails.customer_address }}</p>
    </div>
    <div class="group">
      <p>Customer Phone</p>
      <p>{{ getDetails && getDetails.phone }}</p>
    </div>
    <div class="group">
      <p>Email</p>
      <p>{{ getDetails && getDetails.email ? getDetails.email : "N/A" }}</p>
    </div>
    <div class="group btn-group mt-2">
      <button class="btn btn-receipt" @click.prevent="handleRouteChange('new')">
        <i class="fas fa-redo-alt"></i>
        Another Transaction
      </button>
      <button class="btn btn-receipt" @click.prevent="shareToken()">
        <img
          src="@/assets/buyunits/icons/bi_share_icon.svg"
          alt="Share icon"
          class="img-fluid cursor-pointer"
        />
        Share
      </button>
      <button class="btn btn-receipt" v-print="`printReceipt`">
        <img
          src="@/assets/buyunits/icons/bi_download_icon.svg"
          alt="Print icon"
          class="img-fluid cursor-pointer"
        />
        Download
      </button>
    </div>
    <div class="group" v-if="!isUserLoggedIn">
      <button
        class="btn btn-standard w-100 mt-1"
        @click.prevent="handleRouteChange('home')"
      >
        <i class="fa fa-home"></i>
        BACK TO HOME
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "OrderReceipt",
  computed: {
    ...mapState(["loading", "isUserLoggedIn"]),
    ...mapGetters(["getDetails", "isMerchant", "isMerchantActive"]),
    hypenateToken() {
      return this.$globalFunc.handleTokenSpacing(this.getDetails.token, 4);
    },
    paymentMethod() {
      return this.$globalFunc.capitalizeFirstLetter(this.getDetails.payment_method.replace('_', ' '));
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    handleRouteChange(type) {
      if (type === "home") {
        this.$router.push({
          name: "Home",
        });
      } else {
        if (this.isUserLoggedIn) {
          this.$router.push({
            name: "SellElectricity",
          });
        } else {
          this.$router.push({
            name: "GuestPurchase",
          });
        }
      }
    },
    shareToken () {
      window.open(
        `https://wa.me/?text=N${this.$globalFunc.currency(this.getDetails.amount)} ${this.getDetails.disco} Token for ${this.getDetails.meter_number} Token:${this.hypenateToken} Units:${parseFloat(this.getDetails.units).toFixed(2)} REF:${this.getDetails.transaction_id} ${process.env.VUE_APP_WEBSITE_URL} Help:${process.env.VUE_APP_HELP_LINE}`,
        "_blank"
      );
    },
    copyToken() {
      this.$copyText(this.hypenateToken, undefined, () => {});
      this.$notify({
        title: "Congratulations!",
        text: "Token copied to clipboard",
        type: "success",
      });
    },
  },
};
</script>
<style scoped>
p.token-copy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
p.token-copy img {
  cursor: pointer;
  margin-right: 10px;
}
</style>