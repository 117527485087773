import store from "../../store";
import router from "../../router";
import AuthenticationService from "../../services/AuthenticationService";

export default {
  loginRequest(credentials) {
    store.dispatch("setLoading", true);
    const response = AuthenticationService.login(credentials)
      .then((result) => {
        if (result.data.status) {
          store.dispatch("login", result.data.data);
          return { status: true, message: result.data.message };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          return { status: false, message: err?.response?.data?.message }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  registerRequest(credentials) {
    store.dispatch("setLoading", true);
    const response = AuthenticationService.register(credentials)
      .then((result) => {
        const { status, data, message } = result.data
        if (status) {
          store.dispatch("login", data);
          store.dispatch("handleModal", "register");
          router.push({ name: "UserVerification" });
          return { status, message };
        }
        return { status: true };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          return { status: false, message: err?.response?.data?.message }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  logoutRequest() {
    store.dispatch("setLoading", true);
    const response = AuthenticationService.logout()
      .then(() => {
        if(window.innerWidth <= 1199) {
          store.dispatch("closeSideMenu");
        }
        store.dispatch("logout");
        router.push({
          name: "Home",
        });
        return { status: true };
      })
      .catch((err) => {
        router.push({
          name: "Home",
        });
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          return { status: false, message: "Kindly login to reauthenticate" }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  logoutAsGuestRequest() {
    store.dispatch("setLoading", true);
    const response = AuthenticationService.logout()
      .then(() => {
        return { status: true };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          return { status: false, message: "Kindly login to reauthenticate" }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  forgotPassword(credentials) {
    store.dispatch("setLoading", true);
    const response = AuthenticationService.forgotPassword(credentials)
      .then((result) => {
        if (result.data.status) {
          return { status: true };
        }
        return { status: false };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          return { status: false, message: err?.response?.data?.message }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  resetPassword(credentials) {
    store.dispatch("setLoading", true);
    const response = AuthenticationService.resetPassword(credentials)
      .then((result) => {
        const { status, message } = result.data
        store.dispatch("handleModal", "forgotPassword");
        return { status, message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          return { status: false, message: err?.response?.data?.message }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
