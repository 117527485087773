<template>
    <div class="form-information">
        <h2 class="text-center">Enter Meter Details</h2>
        <p class="small text-center">Enter your meter details to buy electricity</p>
        <form action="#" class="mt-2" @submit.prevent="handleSubmit()">
            <div class="form-group">
                <input name="meter_number" class="form__input bordered mb-1" type="text" v-model="fields.meter_number" placeholder="Enter Meter Number" @keypress="$globalFunc.onlyNumber" autofocus="">
            </div>
            <div class="form-group">
                <select name="country" class="form-control mb-1" v-model="fields.disco_id" @change="setMinimumVendAmount">
                    <option value="" disabled selected>Select Disco</option>
                    <option :value="disco.unique_id" v-for="disco in discos" :key="disco.unique_id">{{ disco.title }}</option>
                </select>
            </div>
            <div class="form-group">
                <input name="amount" class="form__input bordered" :class="[{ 'input-error': amountError }, { 'mb-1': !amountError }]" type="text" v-model="fields.amount" placeholder="Amount" @keyup="validateAllowedValue" @keypress="$globalFunc.onlyNumber" :min="minimumAmount">
            </div>
            <p class="m-0 text-center error" :class="[{ 'mb-1': !amountError }]" v-if="amountError">{{ amountError }}</p>
            <div class="form-group">
                <input name="phone" class="form__input bordered mb-1" type="text" v-model="fields.phone" placeholder="Enter Phone Number" @keypress="$globalFunc.onlyNumber">
            </div>
            <div class="form-group">
                <input name="email" class="form__input bordered mb-1" type="text" v-model="fields.email" placeholder="Email (Optional)">
            </div>
            <div class="form-group" v-if="isUserLoggedIn">
                <input type="checkbox" v-model="fields.save_meter">
                <label class="d-flex align-items-center" style="font-size: 14px">
                    <span class="custom-checkbox"></span> Do you want to save this meter number?
                </label>
            </div>
            <div class="form-group">
                <button class="btn btn-standard mt-1" :disabled="loading">{{ loading ? "Please wait" : "BUY NOW" }}</button>
            </div>
        </form>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import ElectricityHelpers from "@/functions/actions/ElectricityRequest";
export default {
    name: "OrderForm",
    computed: {
        ...mapState(["loading", "phone_number", "isUserLoggedIn"])
    },
    mounted() {
        if (this.$route.query.meter_number) {
            this.fields.meter_number = this.$route.query.meter_number;
        }
        if (this.$route.query.disco_id) {
            this.fields.disco_id = this.$route.query.disco_id;
        }
        if (this.phone_number) {
            this.fields.phone = this.phone_number;
            this.lastTransaction(this.fields.phone);
        }
        this.getDiscos();
    },
    data() {
        return {
            fields: {
                meter_number: "",
                amount: "",
                phone: "",
                disco_id: "",
                platform: "web",
                save_meter: false,
            },
            discos: [],
            amountError: null,
            minimumAmount: 0,
        };
    },
    methods: {
        setMinimumVendAmount() {
            if (this.fields.disco_id) {
                const selectedDisco = this.discos.find(disco => disco.unique_id === this.fields.disco_id);
                this.fields.amount = selectedDisco.minimum_value;
                this.minimumAmount = selectedDisco.minimum_value;
            }
        },
        validateAllowedValue() {
            if (this.fields.disco_id) {
                const selectedDisco = this.discos.find(disco => disco.unique_id === this.fields.disco_id);
                if (this.fields.amount > selectedDisco.maximum_value) {
                    this.amountError = `The maximum amount allowed is ₦ ${this.$globalFunc.currency(selectedDisco.maximum_value)}`;
                    // this.showAlert('Validation Error', this.amountError, 'warn')
                } else if (this.fields.amount < selectedDisco.minimum_value) {
                    this.amountError = `The minimum amount allowed is ₦ ${this.$globalFunc.currency(selectedDisco.minimum_value)}`;
                    // this.showAlert('Validation Error', this.amountError, 'warn')
                } else {
                    this.amountError = null
                }
            }
        },
        async handleSubmit() {
            if (this.validateRequest()) {
                const response = await ElectricityHelpers.verifyElectricity(this.fields)
                if (response.status) {
                // successful, redirect to electricity-details page
                } else {
                    this.showAlert('Error occured', response.message, 'error')
                }
            }
        },
        async lastTransaction(phone) {
            const response = await ElectricityHelpers.lastTransaction(phone)
            if (response.status) {
                this.fields = { ...this.fields, ...response.transaction }
            }
        },
        async getDiscos() {
            const response = await ElectricityHelpers.retrieveDiscos()
            if (response.status) {
                this.discos = response.discos
            } else {
                this.showAlert('Error occured', response.message, 'error')
            }
        },
        validateRequest() {
            if (this.fields.meter_number) {
                if (this.fields.disco_id) {
                    if (this.fields.amount) {
                        if (this.fields.phone) {
                            if (this.fields.phone.length === 11) {
                                return true;
                            } else {
                                this.showAlert('Validation Error', 'Phone number must be 11 digits', 'error')
                            }
                        } else {
                            this.showAlert('Validation Error', 'Kindly provide a phone number', 'error')
                        }
                    } else {
                        this.showAlert('Validation Error', 'Kindly enter an amount', 'error')
                    }
                } else {
                    this.showAlert('Validation Error', 'Kindly select a disco', 'error')
                }
            } else {
                this.showAlert('Validation Error', 'Kindly enter a meter number', 'error')
            }
        },
        showAlert(title, text, type) {
            this.$notify({
                title,
                text,
                type: type ? type : "warn",
            });
        },
    },
}
</script>
<style scoped>
.form-group button {
    width: 70%;
}
.input-error {
    border: 1px solid red;
}
[type="checkbox"] {
    opacity: 0;
    position: absolute;
}
.custom-checkbox {
    min-width: 1.2rem;
    min-height: 1.2rem;
    margin-right: 0.75em;
    border: 2px solid #28aa63;
    display: inline-block;
}
[type="checkbox"]:checked+label .custom-checkbox {
    border-color: #28aa63;
    background: #28aa63;
    box-shadow: inset 0 0 0 2px white;
}
</style>