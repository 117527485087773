import store from "../../store";
import router from "../../router";
import MeterNumbersService from "../../services/MeterNumbersService";

export default {
  getMeterNumbers() {
    store.dispatch("setLoading", true);
    const response = MeterNumbersService.getMeterNumbers()
      .then((result) => {
        if (result.data.status) {
          const { data: { meters } } = result.data;
          return { status: true, meters };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  deleteMeterNumbers(payload) {
    store.dispatch("setLoading", true);
    const response = MeterNumbersService.deleteMeterNumbers(payload)
      .then((result) => {
        if (result.data.status) {
          return { status: true };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
