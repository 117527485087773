<template>
    <section id="about" class="about section section--no-pt ">
        <div class="container">
            <div class="row justify-content-center about__first-row" style="align-items: center">
                <div class="col-lg-12">
                    <div class="block-header text-center">
                        <h2 class="title title--black title--medium mb_5">Buy Units Advantage</h2>
                        <p class="m-0 title--subtitle font-2">Enjoy Amazing experience with these amazing features</p>
                        <hr class="tagline">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4" v-for="advantage in advantages" :key="advantage.id">
                    <div class="card-style text-center about__item">
                        <div class="about__item-icon">
                            <img v-lazy="getImage(advantage.icon)" alt="folder" class="img-fluid d-flex mx-auto mb-2">
                        </div>
                        <div class="about__item-content">
                            <h3 class="title title--black title--medium mb_5">{{ advantage.title }}</h3>
                            <p class="m-0 title--subtitle font-2">{{ advantage.subtitle }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "AboutSection",
    components: {},
    computed: {},
    data() {
        return {
            advantages: [
                {
                    id: 1,
                    icon: "blue-folder.svg",
                    title: "Request Utility Bills",
                    subtitle: "Do you need your utility bills as proof of address? Get it here."
                },
                {
                    id: 2,
                    icon: "orange-folder.svg",
                    title: "Find My Token",
                    subtitle: "Enter meter details to find a lost token."
                },
                {
                    id: 3,
                    icon: "yellow-folder.svg",
                    title: "Unit Calculator",
                    subtitle: "Calculate the amount of units you get for your meter."
                }
            ]
        }
    },
    methods: {
        getImage(icon) {
            return require(`@/assets/buyunits/icons/${icon}`)
        }
    }
}
</script>
<style scoped>   
.about__first-row {
  margin: 70px auto 40px;
}
@media (max-width: 960px) {
    .section {
        padding: 0;
    }
}
.card-style {
    height: 50vh
}
.card-style:hover {
    border: 2px solid #28AA63;
}
.card-style:hover .about__item-content h3 {
    color: #28AA63;
}
.about__item-content h3 {
    font-size: 25px;
    line-height: 2rem;
}
@media (min-width: 1110px) {
    .card-style {
        height: 35vh
    }
}
@media (max-width: 991px) {
    .card-style {
        height: 35vh;
        padding: 30px 15px;
    }
    .card-style.about__item {
        margin-bottom: 1rem;
    }
}
@media (max-width: 480px) {
    .card-style {
        height: auto;
    }
    .card-style.about__item {
        margin-bottom: 1rem;
    }
}
</style>