<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row" style="align-items: center">
        <div class="col-lg-12">
          <div class="block-header">
            <h2 class="title title--black title--medium">Our Services</h2>
          </div>
          <ul class="mt-2">
            <li>We provide developer's with API's connections for electricity bill payments at very competitive rates. Send us an email to <a href="mailto:sadiq@buyunits.ng">sadiq@buyunits.ng</a> to request our API documentation today!</li>
            <li>Distribution and installation of smart meters with intelligent reporting tools and robust dashboard</li>
            <li>Online sales of prepaid units and postpaid electricity via our website or intuitive mobile apps.</li>
          </ul>
        </div>
      </div>
      <div class="row justify-content-start align-items mt-2">
        <router-link to="/">
          <img src="@/assets/buyunits/icons/guest-home.svg" alt="Guest Home" class="img-fluid" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ServicesPage",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
ul {
  margin-bottom: 10px;
  color: #2c2c2c;
  line-height: 30px;
}
ul {
  color: #2c2c2c;
  line-height: 30px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-type: disc;
  margin-block-start: 1em;
  padding-inline-start: 40px;
}
</style>