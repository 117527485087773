import store from "../../store";
import router from "../../router";
import DashboardService from "../../services/DashboardService";

export default {
  dashboardInfo() {
    store.dispatch("setLoading", true);
    const response = DashboardService.dashboardInfo()
      .then((result) => {
        const { status, data: { dashboard } } = result.data;
        return { status, dashboard };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
