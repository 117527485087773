<template>
  <form action="" class="text-center" @submit.prevent="validateToken()">
    <div class="row">
      <div class="col-lg-12 mt-1">
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(1)"
          maxlength="1"
          v-model="pin.one"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(2)"
          maxlength="1"
          v-model="pin.two"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(3)"
          maxlength="1"
          v-model="pin.three"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(4)"
          maxlength="1"
          v-model="pin.four"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(5)"
          maxlength="1"
          v-model="pin.five"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(6)"
          maxlength="1"
          v-model="pin.six"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-2 mb-1">
        <button
          class="btn btn-standard mt-1 w-75"
          type="submit"
          :disabled="loading || combinedOtp.length !== 6"
        >
          {{ !loading ? "Verify Account" : "Verifying..." }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapState } from "vuex";
import AccountSettingsHelpers from "@/functions/actions/AccountSettingsRequest";
export default {
  name: "VerificationPage",
  computed: {
    ...mapState(["loading"]),
    combinedOtp() {
      return `${this.pin.one}${this.pin.two}${this.pin.three}${this.pin.four}${this.pin.five}${this.pin.six}`;
    },
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pin: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
    };
  },
  methods: {
    async validateToken() {
      const payload = {
        token: this.combinedOtp,
      };
      const response = await AccountSettingsHelpers.verifyPhoneToken(payload);
      if (response.status) {
        this.$store.dispatch("updateUserStatus", "active");
        this.$router.push({
          name: "SellElectricity",
        })
      } else {
        this.showAlert("Error occured", "Invalid token provided", "error");
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
form input {
  display: inline-block;
  width: 40px;
  height: 45px;
  text-align: center;
  margin: auto 10px;
}
</style>