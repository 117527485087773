<template>
    <div class="form-information-details">
        <div class="group">
            <h3>Name</h3>
            <p class="small">{{ getDetails && getDetails.customer_name }}</p>
        </div>
        <div class="group">
            <h3>Meter No.</h3>
            <p class="small">{{ getDetails && getDetails.meter_number }}</p>
        </div>
        <div class="group">
            <h3>Transaction ID</h3>
            <p class="small">{{ getDetails && getDetails.transaction_id }}</p>
        </div>
        <div class="group">
            <h3>Address</h3>
            <p class="small">{{ getDetails && getDetails.customer_address }}</p>
        </div>
        <div class="group">
            <h3>Amount</h3>
            <p class="small">&#8358; {{ getDetails && $globalFunc.currency(getDetails.amount) }}</p>
        </div>
        <div class="group">
            <h3>Phone</h3>
            <p class="small">{{ getDetails && getDetails.phone }}</p>
        </div>
        <div class="group">
            <h3>Email</h3>
            <p class="small">{{ getDetails && getDetails.email ? getDetails.email : 'N/A' }}</p>
        </div>
        <div class="group">
            <h3>Disco</h3>
            <p class="small">{{ getDetails && getDetails.disco }}</p>
        </div>
        <div class="group" v-if="isMerchant && isMerchantActive">
            <h3>Commission</h3>
            <p class="small">&#8358; {{ getDetails && $globalFunc.currency(getDetails.commission) }}</p>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import ElectricityHelpers from "../../functions/actions/ElectricityRequest";
export default {
    name: "OrderDetails",
    computed: {
        ...mapState(["loading"]),
        ...mapGetters(["getDetails", "isMerchant", "isMerchantActive"]),
    },
    mounted() {},
    data() {
        return {};
    },
    methods: {
        async handleSubmit() {
            if (this.validateRequest()) {
                const response = await ElectricityHelpers.verifyElectricity(this.fields)
                if (response.status) {
                // successful, redirect to electricity-details page
                } else {
                    this.showAlert('Error occured', response.message, 'error')
                }
            }
        },
        showAlert(title, text, type) {
            this.$notify({
                title,
                text,
                type: type ? type : "warn",
            });
        },
    },
}
</script>
<style scoped>
</style>