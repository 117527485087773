<template>
  <div class="row justify-content-center align-items-center g-0 height-100">
    <div class="col-sm-10 col-lg-6">
      <div class="content auth d-flex flex-column justify-content-center h-100">
        <div class="auth-form">
          <h4 class="text-center mb-2 mt-5">Verify Your Account</h4>
          <p class="small text-center mb-2">
            Please enter the 6 digits verification code sent to your mobile device. If you did not receive a code, please click the button below to resend.
          </p>
          <Verification-Page :phone="user && user.phone" />
          <p class="small text-center mb-2">
            Didn't receive the code? <a href="#" class="resend-token" @click.prevent="resendToken()">Resend</a>
          </p>
          <div class="d-flex align-items-center justify-content-around">
            <p class="small text-center mb-2">
              <a href="#" class="resend-token" @click.prevent="continueAsGuest()">Continue as Guest?</a>
            </p>
            <p class="small text-center mb-2">
              Not yet? <a href="#" class="resend-token" @click.prevent="logoutUser()">Logout</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import VerificationPage from '@/components/Auth/VerificationPage.vue';
import AuthHelpers from "@/functions/actions/AuthRequest";
import AccountSettingsHelpers from "@/functions/actions/AccountSettingsRequest";
export default {
  name: "UserVerification",
  components: {
    VerificationPage,
  },
  computed: {
    ...mapState(["loading", "user"]),
  },
  methods: {
    async resendToken() {
      const response = await AccountSettingsHelpers.resendPhoneToken();
      if (response.status) {
        this.showAlert("Success", response.message, "success");
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    async logoutUser() {
      const response = await AuthHelpers.logoutRequest();
      if (response.status) {
        // redirect user to homepage
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    async continueAsGuest() {
      this.$store.dispatch("continueAsGuest");
      AuthHelpers.logoutAsGuestRequest();
      this.$router.push({
        name: "GuestPurchase",
      });
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>
<style scoped>
.auth-form h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #28aa63;
}
.auth-form p {
  line-height: 30px;
}
</style>