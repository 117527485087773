import { createApp, h } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import axios from "axios";
import router from "./router";
import store from "./store";
import globalFunc from "@/functions/globalFunc";
import Notifications from '@kyvg/vue3-notification';
import VueClipboard from 'vue3-clipboard';
import print from 'vue3-print-nb';
import VueLazyLoad from 'vue3-lazyload'

const app = createApp({
  async created() {
    const token = sessionStorage.getItem("token");
    const details = sessionStorage.getItem("setDetails");
    const activePhoneNumber = sessionStorage.getItem("activePhoneNumber");
    const transactionDetails = sessionStorage.getItem("transactionDetails")
    if (!token) {
      this.$store.dispatch("logout");
    }
    if (details) {
      this.$store.dispatch("reloadUserData", {user: JSON.parse(details), token: JSON.parse(token)});
    }
    if (activePhoneNumber) {
      const phoneNumber = JSON.parse(activePhoneNumber);
      this.$store.dispatch("setUserPhoneNumber", phoneNumber);
    }
    if (transactionDetails) {
      const transactionPayload = JSON.parse(transactionDetails)
      this.$store.dispatch("setTransactionDetails", transactionPayload)
    }
    if (window.innerWidth <= 1150) {
      store.dispatch('toggleSidebar', 1);
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }
        return Promise.reject(error);
      }
    );
  },
  render: () => h(App),
});

app.use(router);

app.use(store);

app.use(Notifications);

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});

app.use(print);

app.use(VueLazyLoad, {
  loading: store.getters.loadingImage,
  error: store.getters.errorImage,
  delay: 500,
});

app.config.globalProperties.$globalFunc = globalFunc;

app.mount("#app");
