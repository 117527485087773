export default {
  currency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },
  capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
  },
  cleanObject(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  },
  generateURLQuery(obj) {
    return new URLSearchParams(obj).toString()
      ? "?" + new URLSearchParams(obj).toString()
      : "";
  },
  onlyNumber(e) {
    if (e.keyCode < 48 || e.keyCode > 57) {
      e.preventDefault();
    }
  },
  generatePasscode(parameter) {
    return CryptoJS.HmacSHA1(
      parameter,
      process.env.VUE_APP_PASSCODE_HASHER
    ).toString();
  },
  handleTokenSpacing(str, n) {
    var ret = [];
    var i;
    var len;
    for (i = 0, len = str.length; i < len; i += n) {
      ret.push(str.substr(i, n));
    }
    return ret.join("-");
  },
  makeDateFriendly(dt) {
    var nowDate = new Date(dt);
    return (
      nowDate.getDate() +
      "/" +
      (nowDate.getMonth() + 1) +
      "/" +
      nowDate.getFullYear()
    );
  },
  makeDateFriendlyWithTime(dt) {
    return `${new Date(Date.parse(dt)).toDateString()} ${new Date(dt)
      .toLocaleString()
      .substring(10, 22)}`;
  },
  convertDateTimetoHuman (date) {
    let dateTime = new Date(date);
    let dateHuman = dateTime.toLocaleString('en-US', {
        month: 'short', // short or long
        day: 'numeric',
        year: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // hour12: false // true or false (24-hour or 12-hour)
    });
    return dateHuman;
  },
  convertDateTimeToDay (date) {
    if (!date) return date
    let dateTime = new Date(date.substring(0, 10));
    return dateTime.toLocaleString('en-US', {
        weekday: 'short'
    });
  }
};
