<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div
        class="row justify-content-center align-items-start mt-1"
       
      >
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-information mt-1">
            <img
              src="@/assets/buyunits/icons/dashboard/bi_calculator-icon.svg"
              alt="unit calcultor icon"
              class="img-fluid d-block mx-auto"
            />
            <h2 class="mb_5 mt-1">Unit Calculator</h2>
            <p class="small text-center">
              You can calculate how many Electricity units you will get for the
              amount you plan to buy.
            </p>
            <form action="#" class="mt-2" @submit.prevent="handleSubmit()">
              <div class="form-group">
                <select
                  name="country"
                  class="form-control mb-1"
                  v-model="fields.disco_id"
                >
                  <option value="" disabled selected>Select Disco</option>
                  <option
                    :value="disco.unique_id"
                    v-for="disco in discos"
                    :key="disco.unique_id"
                  >
                    {{ disco.title }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <input
                  name="meter_number"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.meter_number"
                  placeholder="Enter Meter Number"
                  @keypress="$globalFunc.onlyNumber"
                  autofocus=""
                />
              </div>
              <div class="form-group">
                <input
                  name="amount"
                  class="form__input bordered mb-1"
                  :class="[{ 'input-error': amountError }]"
                  type="text"
                  v-model="fields.amount"
                  placeholder="Amount"
                  @keyup="validateAllowedValue"
                  @keypress="$globalFunc.onlyNumber"
                  :min="minimumAmount"
                />
              </div>
              <div class="form-group">
                <button
                  class="btn btn-standard mt-1"
                  :disabled="loading"
                  v-if="available"
                >
                  {{ loading ? "Please wait" : "Calculate Unit" }}
                </button>
                <button
                  class="btn btn-standard mt-1"
                  :disabled="!available"
                  v-else
                >
                  Coming Soon
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import ElectricityHelpers from "@/functions/actions/ElectricityRequest";
import UnitCalculatorHelpers from "@/functions/actions/UnitCalculatorRequest";
export default {
  name: "UnitCalculatorIndex",
  components: {},
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.getDiscos();
  },
  data() {
    return {
      available: false,
      discos: [],
      amountError: null,
      minimumAmount: 0,
      fields: {
        meter_number: "",
        amount: "",
        disco_id: "",
      },
    };
  },
  methods: {
    setMinimumVendAmount() {
      if (this.fields.disco_id) {
        const selectedDisco = this.discos.find(
          (disco) => disco.unique_id === this.fields.disco_id
        );
        this.fields.amount = selectedDisco.minimum_value;
        this.minimumAmount = selectedDisco.minimum_value;
      }
    },
    validateAllowedValue() {
      if (this.fields.disco_id) {
        const selectedDisco = this.discos.find(
          (disco) => disco.unique_id === this.fields.disco_id
        );
        if (this.fields.amount > selectedDisco.maximum_value) {
          this.amountError = `The maximum amount allowed is ₦ ${this.$globalFunc.currency(
            selectedDisco.maximum_value
          )}`;
          this.showAlert("Validation Error", this.amountError, "warn");
        } else if (this.fields.amount < selectedDisco.minimum_value) {
          this.amountError = `The minimum amount allowed is ₦ ${this.$globalFunc.currency(
            selectedDisco.minimum_value
          )}`;
          this.showAlert("Validation Error", this.amountError, "warn");
        } else {
          this.amountError = null;
        }
      }
    },
    async getDiscos() {
      const response = await ElectricityHelpers.retrieveDiscos();
      if (response.status) {
        this.discos = response.discos;
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    async handleSubmit() {
      if (this.validateRequest()) {
        return false;
        const response = await UnitCalculatorHelpers.calculateUnits(
          this.fields
        );
        if (response.status) {
          this.meters = response.meters;
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    validateRequest() {
      if (this.fields.disco_id) {
        if (this.fields.meter_number) {
          if (this.fields.amount) {
            return true;
          } else {
            this.showAlert(
              "Validation Error",
              "Kindly enter an amount",
              "error"
            );
          }
        } else {
          this.showAlert(
            "Validation Error",
            "Kindly enter a meter number",
            "error"
          );
        }
      } else {
        this.showAlert("Validation Error", "Kindly select a disco", "error");
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>

<style scoped>
.form-information h2 {
  font-size: 35px;
  text-align: center;
}
.form-information form .form-group select.form-control,
.form-information form .form-group input {
  width: 100%;
}
.form-information p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.form-group select {
  border: 1px solid #CBCBCB;
}
</style>