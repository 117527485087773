<template>
  <div>
    <Header></Header>
    <Fixed-Header />
    <main class="main-content">
      <Sidebar-Component />
      <div class="contents" :class="[{ 'expanded': collapsed }]">
        <Progress-Status />
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "@/layout/_partials/Header.vue";
import FixedHeader from "@/layout/_partials/FixedHeader.vue";
import ProgressStatus from "@/components/ProgressStatus.vue";
import SidebarComponent from "@/components/Dashboard/SidebarComponent.vue";
import { mapState } from 'vuex';
export default {
  name: "DashboardLayout",
  computed: {
    ...mapState(["collapsed"]),
  },
  components: {
    Header,
    FixedHeader,
    ProgressStatus,
    SidebarComponent,
  },
};
</script>
<style>
main {
  display: block;
}
.main-content {
  min-height: 100vh;
  position: relative;
}
.contents {
  padding: 0 15px 72px 300px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.layout-dark .sidebar,
.layout-dark .sidebar .sidebar__menu-group ul li ul li > a.active,
.layout-dark .sidebar .sidebar__menu-group ul li ul li > a:hover,
.overlay-dark-sidebar {
  background: #272b41;
}
.layout-dark .sidebar .sidebar__menu-group ul li a.active,
.layout-dark .sidebar .sidebar__menu-group ul li a:hover,
.layout-dark .sidebar .sidebar__menu-group ul li ul li > a.active,
.layout-dark .sidebar .sidebar__menu-group ul li ul li > a:hover {
  color: #fff;
}
.top-menu .sidebar-toggle {
  display: none;
}
.top-menu .sidebar {
  display: none;
}
.navbar-left .sidebar-toggle {
  margin-right: 15px;
}
.sidebar {
  position: fixed;
  height: calc(100% - 74px);
  overflow-y: auto;
  left: 0;
  top: 70px;
  -webkit-box-shadow: 0 0 30px rgba(146, 153, 184, 0.1);
  box-shadow: 0 0 30px rgba(146, 153, 184, 0.1);
  width: 280px;
  background: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 999;
}
.sidebar .menu-text {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.sidebar .menuItem {
  display: inline-block;
}
.sidebar.collapsed {
  width: 76px;
  padding: 0;
}
.sidebar.collapsed .menu-text {
  display: none;
}
.sidebar.collapsed .menuItem {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group {
  margin-bottom: 0;
}
.sidebar.collapsed .sidebar__menu-group > span {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group li {
  position: relative;
}
.sidebar.collapsed .sidebar__menu-group li.menu-title {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group li a {
  width: 42px;
  height: 42px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 15px 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}
.sidebar.collapsed .sidebar__menu-group li a .nav-icon {
  margin-right: 0;
}
.sidebar.collapsed .sidebar__menu-group li a .toggle-icon {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group li a.active,
.sidebar.collapsed .sidebar__menu-group li a:hover {
  background-color: rgba(95, 99, 242, 0.05);
}
.sidebar.collapsed .sidebar__menu-group .has-child ul {
  width: 240px;
  background: #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  right: -225px;
  position: absolute;
  top: 0;
  padding: 10px 20px;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  max-height: 350px;
  overflow: auto;
  z-index: 999;
}
.sidebar.collapsed .sidebar__menu-group .has-child ul li a {
  width: 100%;
  height: auto;
  padding: 8px 0;
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.sidebar.collapsed .sidebar__menu-group .has-child ul li a.active,
.sidebar.collapsed .sidebar__menu-group .has-child ul li a:hover {
  background-color: transparent;
}
.sidebar.collapsed .sidebar__menu-group .has-child:hover ul {
  display: block !important;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.sidebar.collapsed .mCSB_container,
.sidebar.collapsed .mCustomScrollBox {
  overflow: visible;
}
.sidebar__menu-group {
  margin-bottom: 25px;
}
.sidebar__menu-group ul.sidebar_nav {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
}
.sidebar__menu-group ul.sidebar_nav li.menu-title span {
  text-transform: uppercase;
  display: block;
  color: #868eae;
  font-size: 12px;
  font-weight: 500;
  padding: 0 30px;
  margin-bottom: 10px;
}
.sidebar__menu-group ul.sidebar_nav li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 9.05px 30px 9.05px 30px;
  color: #272b41;
  font-size: 14px;
  position: relative;
}
.sidebar__menu-group ul.sidebar_nav li > a .nav-icon {
  color: #adb4d2;
  display: inline-block;
  margin-right: 20px;
  width: 16px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar__menu-group ul.sidebar_nav li > a .toggle-icon {
  font-size: 12px;
  font-family: "Line Awesome Free";
  font-weight: 900;
  margin-left: auto;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.sidebar__menu-group ul.sidebar_nav li > a .toggle-icon:before {
  content: "\f105";
}
.sidebar__menu-group ul.sidebar_nav li > a .menuItem {
  position: absolute;
  right: 52px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: auto;
  font-size: 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 4px 4px;
  line-height: 1;
  letter-spacing: 1px;
  color: #fff;
}
.sidebar__menu-group ul.sidebar_nav li ul {
  padding: 8px 0 12px 36px;
}
.sidebar__menu-group ul.sidebar_nav li.has-child.open > a .toggle-icon:before {
  content: "\f107";
}
.sidebar__menu-group ul.sidebar_nav li ul li > a.active,
.sidebar__menu-group ul.sidebar_nav li ul li > a:hover {
  background-color: transparent;
}
.sidebar-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: transparent;
}
.sidebar-toggle:hover {
  background-color: rgba(95, 99, 242, 0.1);
}
.sidebar-toggle:hover svg #Path_1,
.sidebar-toggle:hover svg #Path_2,
.sidebar-toggle:hover svg #Path_3 {
  fill: #5f63f2;
}
.layout-dark .sidebar .sidebar__menu-group ul li.menu-title span {
  color: rgba(255, 255, 255, 0.38);
}
.layout-dark .sidebar .sidebar__menu-group ul li a {
  color: rgba(255, 255, 255, 0.6);
}
.layout-dark .sidebar .sidebar__menu-group ul li a.active,
.layout-dark .sidebar .sidebar__menu-group ul li a:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
.layout-dark .sidebar .sidebar__menu-group ul li a .nav-icon,
.layout-dark .sidebar .sidebar__menu-group ul li a .toggle-icon {
  color: rgba(255, 255, 255, 0.6);
}
.sidebar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 99;
  margin: 0 30px 0 15px;
  height: 100%;
}
.sidebar-group .left-sidebar-wrap {
  background: #fbfbfb;
  width: 420px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-shadow: 0 5px 20px #9299b808;
  box-shadow: 0 5px 20px #9299b808;
}
.sidebar-group .sidebar:not(.active) {
  display: none;
}
.fileM-sidebar {
  height: 100vh;
  background: #fff;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.fileM-sidebar .fileM-types {
  margin: 0 0 63px 0;
}
.fileM-sidebar .fileM-types .sidebar__menu-group ul.sidebar_nav {
  margin: 21px 0 0 0;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.has-child
  > ul {
  visibility: hidden;
  opacity: 0;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.has-child.open
  > ul {
  visibility: visible;
  opacity: 1;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.menu-title
  span {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  padding: 0 14px;
}
.fileM-sidebar .fileM-types .sidebar__menu-group ul.sidebar_nav li > a {
  padding: 0 5px 0 14px;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  > a
  .toggle-icon {
  position: absolute;
  left: -2px;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  > a
  .nav-icon {
  margin-right: 10px;
}
.fileM-sidebar .fileM-types .sidebar__menu-group ul.sidebar_nav li ul {
  padding: 0 0 0 25px;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  > a
  .nav-icon {
  width: 14px;
}
.fileM-sidebar ul li a {
  color: #5a5f7d;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.fileM-sidebar ul li a svg {
  color: #adb4d2;
  width: 18px;
  margin-right: 13px;
}
.fileM-sidebar ul li a.active {
  color: #5f63f2;
}
.fileM-sidebar ul li a.active svg {
  color: #5f63f2;
}
.fileM-sidebar ul li a:hover {
  color: #5f63f2;
}
.sidebar.collapsed .sidebar__menu-group ul.sidebar_nav li.menu-title span {
  display: none;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contents.expanded {
  padding-left: 93px;
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.contents.expanded + .footer-wrapper {
  padding-left: 93px;
}
.contents.expanded:focus {
  outline: 0;
  -webkit-box-shadow: 0 5px 20px rgba(95, 99, 242, 0.1);
  box-shadow: 0 5px 20px rgba(95, 99, 242, 0.1);
}
@media only screen and (min-width: 991px) and (max-width: 992px) {
  .sidebar-group .left-sidebar-wrap {
    width: 360px;
  }
}
@media only screen and (max-width: 1399px) {
  .sidebar.collapsed .sidebar__menu-group .has-child ul {
    max-height: 300px;
  }
}
@media (max-width: 1150px) {
  .contents {
    padding: 73px 15px 72px 16px;
  }
  .contents.expanded {
    padding-left: 16px;
  }
  .contents.expanded + .footer-wrapper {
    padding-left: 0;
  }
  .sidebar.collapsed {
    left: -76px;
  }
}
@media only screen and (max-width: 991px) {
  .contents {
    padding: 73px 15px 64px 16px;
  }
  .contents.expanded + .footer-wrapper {
    padding-left: 8px;
  }
  .customizer-overlay.show,
  .overlay-dark-sidebar.show {
    z-index: 99;
  }
  .top-menu .sidebar {
    display: block;
  }
  .sidebar-group {
    margin: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar {
    top: 56px;
  }
}
@media only screen and (max-width: 479px) {
  .sidebar-group {
    margin: 0 15px;
  }
  .sidebar-group .left-sidebar-wrap {
    width: 100%;
  }
}
@media only screen and (max-width: 479px) and (max-width: 479px) {
  .sidebar-group.chat_sidebar {
    margin: 0;
  }
}
</style>