import Api from "@/services/Api";

export default {
  getProfileInfo() {
    return Api().get("/users/profile");
  },
  getProfileInfo() {
    return Api().get("/users/commission");
  },
  dashboardInfo() {
    return Api().get("/users/dashboard/info");
  },
};