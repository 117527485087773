<template>
  <div class="dashboard-card mb-1" v-if="type == 'wallet'">
    <img src="@/assets/buyunits/half-elipse.svg" class="half-elipse" />
    <p class="wallet-top m-0">Wallet Balance</p>
    <p class="wallet-amount m-0">&#8358;{{ walletBalance ? $globalFunc.currency(walletBalance) : '0.00' }}</p>
  </div>
  <div class="dashboard-card mb-1" v-else>
    <img src="@/assets/buyunits/half-elipse.svg" class="half-elipse" />
    <p class="wallet-top m-0">Today's Profit</p>
    <p class="wallet-amount m-0">
      &#8358;{{ payload ? $globalFunc.currency(payload[0]) : '0.00' }} 
      <span :class="checkStats ? 'increase' : 'decrease'"><i :class="`fa fa-arrow-${checkStats ? 'up' : 'down'}`" aria-hidden="true"></i></span>
    </p>
    <span :class="!checkStats ? 'increase' : 'decrease'">
      Yesterday: &#8358;{{ payload ? $globalFunc.currency(payload[1]) : '0.00' }} &nbsp;
      <i :class="`fa fa-arrow-${!checkStats ? 'up' : 'down'}`" aria-hidden="true"></i>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "WalletCard",
  props: {
    type: {
      type: [String],
      required: true,
    },
    payload: {
      type: [Number, Array],
      required: false,
    },
  },
  computed: {
    ...mapGetters(["walletBalance"]),
    checkStats() {
      if (this.payload) {
        return this.payload[0] > this.payload[1] ? true : false;
      }
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.wallet-top {
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1d7947;
}
.wallet-amount {
  font-family: "Maven Pro", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #333333;
}
.dashboard-card {
  height: 18vh;
}
.dashboard-card span {
 font-size: 13px;
}
.dashboard-card .increase {
  color: #1d7947;
}
.dashboard-card .decrease {
  color: #ff0000;
}
</style>