import { createStore } from 'vuex'

export default createStore({
  state: {
    loading: false,
    images: {
      loading: require('@/assets/image-loading.png'),
      error: require('@/assets/error-loading.png'),
    },
    token: null,
    user: null,
    isUserLoggedIn: false,
    phone_number: null,
    service: null,
    dashboard: null,
    details: null,
    isOpen: false,
    steps: {
      isActive: false,
      current: 1,
    },
    modal: {
      login: false,
      register: false,
      forgotPassword: false,
    },
    collapsed: false,
  },
  getters: {
    isUser: state => {
      return state?.user
    },
    isUserActive: state => {
      return state?.user?.status === 'active'
    },
    isMerchant: state => {
      return state?.user?.merchant
    },
    isMerchantActive: state => {
      return state?.user?.merchant?.status === 'active'
    },
    currentStep: state => {
      return state.steps.current
    },
    isStepActive: state => {
      return state.steps.isActive
    },
    getDetails: state => {
      return state.details
    },
    getDashboardInfo: state => {
      return state.dashboard
    },
    getCommissions: state => {
      return state.dashboard?.user_commission
    },
    walletBalance: state => {
      return state?.user?.wallet
    },
    loadingImage: state => {
      return state.images.loading
    },
    errorImage: state => {
      return state.images.error
    }
  },
  mutations: {
    SET_LOADING_STATE(state, type) {
      state.loading = type;
    },
    SET_USER_PHONE_NUMBER(state, phoneNumber) {
      state.phone_number = phoneNumber;
      sessionStorage.setItem("activePhoneNumber", JSON.stringify(phoneNumber));
    },
    REMOVE_USER_PHONE_NUMBER(state) {
      state.phone_number = null;
      sessionStorage.removeItem("activePhoneNumber");
    },
    SET_TRANSACTION_DETAILS(state, payload) {
      state.details = payload;
      state.service = payload.service;
      sessionStorage.setItem("transactionDetails", JSON.stringify(payload));
    },
    CLEAR_TRANSACTION_DETAILS(state) {
      state.details = null;
      state.service = null;
      sessionStorage.removeItem("transactionDetails");
    },
    HANDLE_MODAL(state, page) {
      if (page === 'login') {
        state.modal.login = !state.modal.login;
        state.modal.register = false;
        state.modal.forgotPassword = false;
      } else if (page === 'register') {
        state.modal.register = !state.modal.register;
        state.modal.login = false;
        state.modal.forgotPassword = false;
      } else {
        state.modal.forgotPassword = !state.modal.forgotPassword;
        state.modal.login = false;
        state.modal.register = false;
      }
    },
    OPEN_SIDE_MENU(state) {
      state.isOpen = true;
    },
    CLOSE_SIDE_MENU(state) {
      state.isOpen = false;
    },
    TOGGLE_SIDEBAR(state, visible) {
      state.collapsed = visible === 0 ? false : true;
    },
    TOGGLE_USE_STEPS(state, visible) {
      state.steps.isActive = visible === 0 ? false : true;
    },
    UPDATE_CURRENT_STEPS(state, step) {
      state.steps.current = step;
    },
    SET_USER_INFO(state, credentials) {
      state.token = credentials?.token?.secret;
      state.user = credentials?.user;
      state.user.merchant = credentials?.merchant;
      state.user.wallet = credentials?.wallet;
      state.phone_number = credentials?.user?.phone;
      sessionStorage.setItem("token", JSON.stringify(state.token));
      sessionStorage.setItem("setDetails", JSON.stringify(state.user));
      state.isUserLoggedIn = true;
    },
    UPDATE_USER_WALLET(state, balance) {
      state.user.wallet = balance;
      sessionStorage.setItem("setDetails", JSON.stringify(state.user));
    },
    UPDATE_MERCHANT_INFO(state, credentials) {
      state.user.merchant = credentials;
      sessionStorage.setItem("setDetails", JSON.stringify(state.user));
    },
    UPDATE_USER_PROFILE(state, profile) {
      state.user = {...state.user, ...profile};
      sessionStorage.setItem("setDetails", JSON.stringify(state.user));
    },
    UPDATE_USER_STATUS(state, status) {
      state.user = {...state.user, status};
      sessionStorage.setItem("setDetails", JSON.stringify(state.user));
    },
    SET_DASHBOARD_INFO(state, payload) {
      state.dashboard = payload;
      state.user.wallet = payload.wallet_balance;
      sessionStorage.setItem("setDetails", JSON.stringify(state.user));
    },
    RELOAD_USER_DATA(state, credentials) {
      state.token = credentials.token;
      state.user = credentials.user;
      state.isUserLoggedIn = true;
    },
    CLEAR_USER_DATA(state) {
      state.token = null;
      state.user = null;
      state.isUserLoggedIn = false;
      state.phone_number = null
      state.details = null
      state.service = null
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("setDetails");
      sessionStorage.removeItem("activePhoneNumber");
      sessionStorage.removeItem("transactionDetails");
    },
    CONTINUE_AS_GUEST(state) {
      state.token = null;
      state.user = null;
      state.isUserLoggedIn = false;
      state.details = null
      state.service = null
      sessionStorage.removeItem("token");
    },
  },
  actions: {
    setLoading({ commit }, type) {
      commit("SET_LOADING_STATE", type);
    },
    setUserPhoneNumber({ commit }, phoneNumber) {
      commit("SET_USER_PHONE_NUMBER", phoneNumber);
    },
    login({ commit }, credentials) {
      commit("SET_USER_INFO", credentials);
      commit("SET_USER_PHONE_NUMBER", credentials?.user?.phone);
    },
    setUserPhoneNumber({ commit }, phoneNumber) {
      commit("SET_USER_PHONE_NUMBER", phoneNumber);
    },
    removeUserPhoneNumber({ commit }) {
      commit("REMOVE_USER_PHONE_NUMBER");
    },
    setTransactionDetails({ commit }, details) {
      commit("SET_TRANSACTION_DETAILS", details);
    },
    clearTransactionDetails({ commit }) {
      commit("CLEAR_TRANSACTION_DETAILS");
    },
    reloadUserData({ commit }, credentials) {
      commit("RELOAD_USER_DATA", credentials);
    },
    updateMerchantInfo({ commit }, credentials) {
      commit("UPDATE_MERCHANT_INFO", credentials);
    },
    updateUserWallet({ commit }, balance) {
      commit("UPDATE_USER_WALLET", balance);
    },
    updateUserProfile({ commit }, profile) {
      commit("UPDATE_USER_PROFILE", profile)
    },
    updateUserStatus({ commit }, status) {
      commit("UPDATE_USER_STATUS", status)
    },
    setDashboardInfo({ commit }, payload) {
      commit("SET_DASHBOARD_INFO", payload);
    },
    handleModal({ commit }, page) {
      commit("HANDLE_MODAL", page);
    },
    openSideMenu({ commit }) {
      commit("OPEN_SIDE_MENU");
    },
    closeSideMenu({ commit }) {
      commit("CLOSE_SIDE_MENU");
    },
    toggleSteps({ commit }, visible) {
      commit("TOGGLE_USE_STEPS", visible);
    },
    toggleSidebar({ commit }, visible) {
      commit("TOGGLE_SIDEBAR", visible);
    },
    updateCurrentStep({ commit }, step) {
      commit("UPDATE_CURRENT_STEPS", step);
    },
    continueAsGuest({ commit }) {
      commit("CONTINUE_AS_GUEST");
    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA");
    },
  },
  modules: {
  }
})
