<template>
  <section id="about" class="about section font-2">
    <div class="container">
      <div class="row" style="align-items: center">
        <div class="col-lg-12">
          <div class="block-header">
            <h2 class="title title--black title--medium">Terms of Service</h2>
          </div>
          <p>
            <strong>PREAMBLE</strong><br/>
            Buyunits ("the Financial Services and Vending Platform", "Platform", "Service") is an electronic distribution platform that allows individuals purchase virtual products and services such as airtime, mobile data and internet subscriptions. It provides quick and convenient access to bill payments including electricity and TV subscriptions. Money transfer services are also available these terms for the purpose of these Terms of service, "you" or "your" means the users of this mobile application and its services,
            "we", "us" or "our" refers to Buyunits Ltd.<br />
            By using Buyunits, you agree to these terms ("the Buyunits Terms of Service").
          </p>
          <p>
            <strong>PLEASE READ CAREFULLY AS USE OF THE PLATFORM INDICATES YOUR ACCEPTANCE OF THESE TERMS.</strong>
          </p>
          <p>
            <strong>These terms Other Definitions:</strong>
          </p>
          <p>
            "Account", "Wallet" refers to an electronic stored value account created for use by registered customers. Credits, debits and charges are applied to this account.
          </p>
          <p>
            A "Guest" is a customer that accesses products and services on the platform without creating an Buyunits account.
          </p>
          <p>
            "Registered phone number" and "Registered Email address" refer to the phone number and/or email address provided at the point of account creation as the official contact details of the User.
          </p>
          <p>
            "Voucher" refers to codes that can be applied either as payment for transactions or as a method of wallet funding. Voucher codes can only be generated by Buyunits.
          </p>
          <p>
            "Money transfers" refer to transfers of funds to a bank account, e-wallet or mobile phone as applicable
          </p>
          <p>
            "Card" Debit or Credit payment instrument in the form of a plastic card provided to you by your financial institution.
          </p>
          <p>
            "USSD payment" refers to payment made directly from your account by generating a USSD code specific to your financial institution and authorized by you.
          </p>
          <p>
            "Customer Service" refers to our customer support team and all the accessible channels to reach us, including but not limited to <br>
            Telephone: <a :href="`tel:${mobile}`">{{ mobile }}</a>;<br>
            Email: <a :href="`mailto:${email_info}`">{{email_info}}</a> and <a :href="`mailto:${email_support}`">{{ email_support }}</a>;</p>
          <p>
            <strong>Minimum Age and Ability to Bind</strong><br />
            This service is available only to Individuals that can form legally
            binding contracts under applicable laws. If you do not qualify, you
            are not permitted to use this service. If you are using the Service
            on behalf of any individual you represent and warrant that you have
            the ability to bind such organization by your use of the Service.
            You agree to provide true, accurate, current and complete
            information about yourself as applicable, as requested in the
            registration form and elsewhere on the platform, and agree to update
            such information if it changes. e. Persons who are under 18 years of
            age should review these terms and conditions with a parent or
            guardian.
          </p>
          <p>
            <strong>Our Offers:</strong> <br>
            Buyunits is a service offered by Buyunits and designed to allow you to:
          </p>
          <ul>
            <li>Sign Up or Create An Account;</li>
            <li>Credit Your Buyunits Wallet By Any Means Available On The Platform;</li>
            <li>Access To Multiple Services Such As Airtime &amp; DATA Top Up, Bill Payments, Cable TV And Money Transfer Services.</li>
          </ul>
          <p>
            <strong>Communication with You</strong> <br>
            We reserve the right to send messages to you to inform you of changes or additions to the service, website, applications, these terms or these terms. Violations of these terms or actions relating to your privilege to access and use the service, or any other matter related to the service or these terms. Nothing in this provision shall require or obligate us to send any notice if no notice is required or mandated elsewhere in these terms. By agreeing to these terms, you acknowledge that we may contact you via telephone (either by a live person, automatic dialer, prerecorded message or a combination of the foregoing) or emails to discuss the product and you consent to such contact. Further, you consent to receive such phone calls and SMS at the telephone number you entered on the platform. Upon request, we may also contact you via telephone (including by automatic dialer or prerecorded message) or send you an SMS in order to provide you with your password or other information you request.
          </p>
          <p>
            <strong>Wallet and Transaction Records</strong> <br>
            As a registered user, you may generate reports or request a record of transactions on your account at any time. Unless otherwise noted at time of request, all records of transactions will be provided free of charge, with the exception of SMS or network charges. You must inform us within 30 days of the date of any transaction. Should you fail to do this within this timeframe, it will be interpreted as you waiving the right to dispute any transactions reflected on the statement or to recover any losses from unauthorized transactions reflected on the receipt/summary.
          </p>
          <p>
            <strong>Fees and Entitlements</strong><br>
            You will not be entitled to any commission throughout the period of you being an Buyunits guest/user. The convenience fee, where applicable will be added to the value of purchase made. The applicable Fees are subject to change at any time at our sole
            discretion, and if you do not agree to any such changes, you should contact us to close your account. You will receive a notification at the point of payment notifying you of the significant charges applicable for the intended product.
          </p>
          <p>
            <strong>Restrictions on Use</strong><br>
            Prohibited Content and Commerce Statement. We prohibit the Service from use by any party that is involved in the sale or distribution of materials or information that are legally prohibited in Nigeria.
          </p>
          <p>
            <strong>Use of Service</strong><br /> 
            You further agree to comply with the following in connection with your use of the Service: <br>
            You may not access or use the Service in a way that uses technology or other means to access, index, re-render, frame, mirror, truncate, add to, inject, filter or link to the Buyunits Website (including by removing, disabling, bypassing, or circumventing any content protection or access control mechanisms intended to prevent the unauthorized use, download, linking, framing, reproduction, access to, or distribution of the API plug in). You may not use any deep-link, page-scrape, robot, crawl, index, spider, offline reader, click spam, macro programs, internet agent, or other automatic device, program, algorithm or methodology which does the same things, to use, access, copy, index, acquire information, generate impressions or clicks, input information, store information, search, generate searches, or monitor any portion of the Service while on your website for any unauthorized purpose. You may not use the Service in a way that, to be determined in our sole discretion damages, disables, overburdens, impairs, or gains unauthorized access to Buyunits"s servers, computer network, or user accounts. You may not use the Service in a way that removes, modifies, disables, blocks, obscures or otherwise impairs any advertising in connection with Buyunits. You may not copy, display, distribute, download, license, modify, publish, re-post, reproduce, reuse, sell, transmit, and use to create a derivative work, or otherwise use the content of the Service on your website for public or commercial purposes without our express written permission. Users can only set up single account to avoid duplication
          </p>
          <p>
            <strong>ACCESS TO DATA</strong><br>
            All sensitive data such as customer PINs and passwords are stored in encrypted format throughout our systems and databases. We also protect our systems and your data form external access by using multiple levels of firewalls. Buyunits staff and customer service representatives cannot access your data without system authorization. They do not have access to customer PINs or passwords and will never ask you to provide this information. <br>
            When signing up for an Buyunits account, you will be prompted to create a unique username and password. You are responsible for the safekeeping and proper use of this data. <br>
            You are responsible for all transactions that take place on your account with your PIN and you indemnify us against any claims made in respect of such transactions. <br>
            At any time you believe or discover that your PIN has been stolen or compromised, you should contact our Customer Service immediately. We will place your account on hold to prevent any transactions from being carried out as soon as we reasonably can. You will remain responsible for all transactions that occur until your account is put on hold.<br>
            Should you dispute any purchase or withdrawal debited to your account, you will be required to prove that it lacked your authorization. Such transactions will be investigated once we receive an affidavit clearly stating that you had not authorized the transaction, supported by proof of this. <br>
            We will use your registered phone number and a selection of questions to confirm your identity when you call Customer Service. This method gives us your authorization to service your account and execute your instructions.
          </p>
          <p>
            <strong>ANTI-BULLYING, HARASSMENT AND DISCRIMINATION POLICY</strong><br>
            All the information we collect from you and about you are used in the following ways: To operate and administer your Buyunits account securely. To carry out your instructions to make payments and undertake your transactions using our service and send you notification of the transaction and payment. To notify you about changes to our terms and policies. To communicate with you on updates, complaints and feedback. To improve our internal customer service training. For Quality Assurance purposes. For customer service including answering questions and responding to feedback and complaints. To enhance security to our Service and the Buyunits account.
          </p>
          <p>
            <strong>HOW WE STORE AND PROTECT YOUR INFORMATION</strong><br>
            This policy covers any bullying, discrimination &amp; harassment that takes place at any of our Merchant location, Merchant website/platform, social media pages and any other place.
          </p>
          <p>
            <strong>Our Position:</strong><br>
            We are committed to providing a platform free from bullying and harassment, thereby ensuring that all our Staff, Users, Merchants and Merchant customers are treated and treat others with respect, consideration, courtesy and dignity at all times. Our platform has a duty to provide a safe environment and platform for all its users, bullying and harassment are unlawful and will not be tolerated by us.
          </p>
          <p>
            <strong>Bullying:</strong><br>
            May be described as repeated and unreasonable behavior directed toward a person, or a group of people, that creates a risk to health, safety and well-being Examples of behavior that may constitute bullying include, but are not limited to: Intimidation;
            Verbal abuse or threats, including yelling, screaming, offensive language at the customer or attendee.
          </p>
          <p>
            <strong>Cyber bullying;</strong>
          </p>
          <p>Physical, intellectual or emotional abuse.</p>
          <p>
            <strong>Harassment:</strong> is any form of behavior that is unwelcome, unsolicited,
            unreciprocated and usually (but not always) repeated. It is behavior
            that is likely to offend, humiliate, or intimidate.
          </p>
          <p>Example:</p>
          <p>
            Offensive, derogatory language or intimidating actions or behavior;
          </p>
          <p>
            Insulting or threatening gestures, language (overt or implied) or
            continual and unwarranted shouting.
          </p>
          <p>Can be threatening, blackmailing or demanding in nature.</p>
          <p>
            Phone calls, messages, posts, comments on voice mails or electronic
            mail or computer networks which are demeaning, threatening, abusive,
            humiliating, or offensive to staff;
          </p>
          <p>
            <strong>Discrimination:</strong> refers to treating a person, or a group of people
            less favorable than another person or group and can be on the
            grounds or situations listed below. Discrimination on the grounds
            of:
          </p>
          <p>
            Gender or Gender identity Sexual orientation; Marital status, or
            pregnancy; Family/career responsibilities; Language; Class, caste,
            social origin; Race, nationality, ethnicity; skin colour; Level or
            type of education; Religious beliefs (or lack of); Health status
            (dependent on role requirements); Disability or impairment; Age
            Trade union activity (or lack of). Political or particular belief
            (or lack of).
          </p>
          <p>
            <strong>No Rights in Software</strong>
          </p>
          <p>
            This is an Agreement for services and access to the platform, except
            as expressly set forth herein, you are not granted a license to any
            software by these terms and nothing on the Site shall be construed
            to confer any grant or license of any intellectual property rights,
            whether by implication, or otherwise. You will not, directly or
            indirectly, reverse engineer, decompile, disassemble, or otherwise
            attempt to discover the source code, object code, or underlying
            structure, ideas, or algorithms of, or found at or through the
            platform or any software, documentation, or data related to the
            platform; remove any proprietary notices or labels from the Website
            or Service or any Software; modify, translate, or create derivative
            works based on the platform or any Software; or copy, distribute,
            pledge, assign, or otherwise transfer or encumber rights to the
            platform or any Software.
          </p>
          <p>Assistance.</p>
          <p>
            You acknowledge that we may from time to time provide you with
            marketing advice and other coaching, template design, frequently
            asked questions and tips on best practices and complying with
            applicable law, including our Privacy Policy and any sample Offer
            Terms. You acknowledge that such assistance and information is
            provided as a convenience to you and that such assistance and
            information are not intended to and do not constitute legal advice
            and that no attorney-client relationship is formed. We do not
            warrant or guarantee that use of or compliance with this information
            will be sufficient to comply with your obligations hereunder,
            applicable law or with third party rights.
          </p>
          <p>
            <strong>Membership Termination/ Notice of discontinuation</strong>
          </p>
          <p>
            We reserve the right to modify, revise, suspend or discontinue any
            membership on this Platform in whole or in part, either temporarily
            or permanently and with a notice of 5 working days. If you wish to
            discontinue your membership on this Platform you are to contact us
            via mail with 10 working days advance notice of discontinuation.
          </p>
          <p>
            NOTE: Notice of discontinuation does not equal membership
            termination. A notice of discontinuation is only the first step to
            membership termination and only refers to the email communication
            you send us expressing your desire to terminate membership. You are
            solely responsible for terminating your Buyunits account and these
            terms. We are not responsible for your failure to properly terminate
            your account and these terms or for any credit card or other charges
            or fees you incur as a result of your failure to properly terminate
            your account and these terms. These terms and any rights or licenses
            granted to you hereunder, remains valid from the period you send the
            notice of discontinuation to the point in time when your membership
            is actually terminated. Hence upon termination of your account by
            you or us, these terms and any rights or licenses granted to you
            hereunder, shall immediately terminate.
          </p>
          <p>
            <strong>Warranty Disclaimer; Remedies; Release</strong>
          </p>
          <p>
            You expressly agree that the platform are provided on an &quot;as
            is&quot; and &quot;as available&quot; basis. Use of the website or
            the platform and any reliance by you upon the platform, including
            any action taken by you because of such use or reliance, is at your
            sole risk. We do not warrant that the use of the site or the service
            will be uninterrupted or error free, nor do we make any warranty as
            to the results that may be obtained from use of the same. We
            disclaim all warranties, express or implied, including, but not
            limited to, implied warranties of merchantability, fitness for a
            particular purpose and non-infringement. No statement or
            information, whether oral or written, obtained from us in any means
            or fashion shall create any warranty not expressly and explicitly
            set forth in these terms. We shall have no liability of any nature
            whatsoever for your compliance with or breach of any license or
            terms and conditions of any third parties or third-party services.
            We do not endorse and are not responsible for (a) the accuracy or
            reliability of any third-party content, opinion, advice or statement
            made by anyone other than us, or (b) any event hosted, donation
            solicited, the results of any survey, or any product or service
            purchased or otherwise obtained from any third party, including our
            customers. No claim may be asserted by you against us more than 12
            months after the date of the cause of action underlying such claim.
            Your sole and exclusive remedy for any failure or non-performance of
            the site or the service shall be for us to use commercially
            reasonable efforts to adjust or repair the site or the service. We
            will not be held liable for any fraudulent transactions fulfilled or
            paid for through the platform originating from your device, account,
            or application. You offer your device or application for use by
            others outside your organization, you agree to use any procedures,
            controls and measures that are appropriate for your business to
            reduce the risk of fraud. In the event that you suspect any
            fraudulent activity by a customer, you agree to notify Buyunits
            immediately and quit the delivery of the service. In addition, where
            we suspect that there have been frequent fraudulent transactions on
            your account, we reserve the right to cancel our service to you
            and/or your account. To the extent the law permits, you release us
            from any claims or liability related to the conduct of any other
            customers of ours or their respective subscribers.
          </p>
          <p>
            <strong>Limitation of Liability</strong>
          </p>
          <p>
            Under no circumstances and under no legal theory, tort, contract, or
            otherwise, shall Buyunits limited or any of its underlying service
            providers, business partners, third party suppliers and providers
            and members of our network, account providers, licensors, officers,
            directors, employees, distributors or agents (collectively referred
            to for purposes of this section as &quot;constant contact&quot;) be
            liable to you or any other person for any money damages, whether
            direct, indirect, special, incidental, cover, reliance or
            consequential damages, even if Buyunits limited shall have been
            informed of the possibility of such damages, or for any claim by any
            other party, and regardless of the form of action (whether in
            contract, tort (including negligence), product liability or
            otherwise), the maximum aggregate liability of Buyunits Limited to
            you arising in connection with these terms shall be limited to the
            reseller fee due to you from the resale of Buyunits services as an
            Buyunits user. You agree that Buyunits limited has set its
            reseller fees and entered into these terms in reliance upon the
            disclaimers of warranty and the limitations of liability set forth
            herein, that they reflect an allocation of risk between the parties
            (including the risk that a contract remedy may fail of its essential
            purpose and cause consequential loss), and that they form an
            essential basis of the bargain between the parties.
          </p>
          <p><strong>Website:</strong> {{ website_url }}</p>
          <p><strong>Email:</strong> {{ email_support }}</p>
          <p>
            <strong>Address:</strong> {{ address }}
          </p>
          <p><strong>Telephone:</strong> {{ mobile }}</p>
          <p>
            Your use of the Buyunits platform and /or any other information
            exchange media, made available by us "Buyunits
            Limited" (collectively called "Buyunits") is
            governed by these Terms of Service.
          </p>
          <br />
          <p>
            <i>Last updated on: <strong>July 1st, 2022</strong></i>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TermsOfService",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {
      website_url: process.env.VUE_APP_WEBSITE_URL,
      address: process.env.VUE_APP_ADDRESS,
      email_info: process.env.VUE_APP_EMAIL_INFO,
      email_support: process.env.VUE_APP_EMAIL_SUPPORT,
      mobile: process.env.VUE_APP_HELP_LINE,
    };
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 15px;
  text-align: justify;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
dl, ol, ul {
  margin-bottom: 10px;
  color: #2c2c2c;
  line-height: 30px;
}
ul {
  color: #2c2c2c;
  line-height: 30px;
  margin: 0;
  padding: 0;
  list-style-type: disc;
  margin-block-start: 1em;
  padding-inline-start: 40px;
}
</style>