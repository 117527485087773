import Api from "@/services/Api";

export default {
  retrieveDiscos() {
    return Api().get("/discos");
  },
  lastTransaction(phone) {
    return Api().get(`/last/transaction/${phone}`);
  },
  verifyElectricity(payload) {
    return Api().post("/electricity/verify", payload);
  },
  vendElectricity(payload) {
    return Api().post("/electricity/vend", payload);
  }
};