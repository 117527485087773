<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row justify-content-center align-items mt-1">
        <img src="@/assets/buyunits/icons/bi_arrow-left-short.svg" alt="Guest Home" class="img-fluid cursor-pointer" @click.prevent="changeRoute()" />
        <div class="upper-section  ml-1">
          <h2 class="text-center">Please Confirm Details</h2>
          <p class="smaller m-0 text-center">Meter No: {{ getDetails && getDetails.meter_number }}</p>
        </div>
      </div>
      <div class="row justify-content-center align-items-center mt-2">
        <div class="col-lg-10">
          <Order-Details />
        </div>
      </div>
      <div class="row justify-content-center align-items-center mt-2">
        <div class="col-lg-4">
          <Payment-Option />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import OrderDetails from "@/components/Service/OrderDetails";
import PaymentOption from "@/components/Payment/Index.vue";
export default {
  name: "DashboardSellDetails",
  components: {
    OrderDetails,
    PaymentOption,
  },
  computed: {
    ...mapState(["loading", "phone_number"]),
    ...mapGetters(["getDetails"]),
  },
  beforeMount() {
    if (!this.getDetails) {
      this.$router.push({
        name: "SellElectricity"
      })
    }
    this.$store.dispatch('toggleSteps', 1);
    this.$store.dispatch('updateCurrentStep', 2);
  },
  mounted() {},
  beforeUnmount() {
    this.$store.dispatch('toggleSteps', 0);
  },
  data() {
    return {};
  },
  methods: {
    changeRoute() {
      if (confirm("Are you sure you want to cancel this transaction?")) {
        this.$router.push({
          name: "SellElectricity"
        })
      }
    }
  }
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
</style>