<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div
        class="row justify-content-center align-items-start mt-1"
       
      >
        <div class="col-12 col-md-6 col-lg-5" v-if="!isMerchant">
          <div class="form-information mt-1">
            <h2 class="mb_5 mt-1">Upgrade to a Merchant</h2>
            <form action="#" class="mt-2" @submit.prevent="handleSubmit()">
              <div class="form-group">
                <input
                  name="business_name"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.business_name"
                  placeholder="Business Name"
                  autofocus=""
                />
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="phone"
                      class="form__input bordered mb-1"
                      type="text"
                      v-model="fields.business_phone"
                      placeholder="Phone Number"
                      @keypress="$globalFunc.onlyNumber"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="email"
                      class="form__input bordered mb-1"
                      type="email"
                      v-model="fields.business_email"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="website"
                      class="form__input bordered mb-1"
                      type="url"
                      v-model="fields.business_website"
                      placeholder="Your Website URL (Optional)"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      name="bvn"
                      class="form__input bordered mb-1"
                      type="text"
                      v-model="fields.bvn"
                      placeholder="BVN"
                      @keypress="$globalFunc.onlyNumber"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group d-block">
                    <label for="id_type">Proof of ID</label>
                    <select
                      name="id_type"
                      class="form-control mb-1"
                      v-model="fields.id_type"
                    >
                      <option value="" disabled selected>Select ID Type</option>
                      <option value="National ID">National ID Card</option>
                      <option value="Drivers License">Drivers License</option>
                      <option value="Voters Card">Voters Card</option>
                      <option value="International Passport">
                        International Passport
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group d-block">
                    <label for="id_type">ID Number</label>
                    <input
                      name="id_number"
                      class="form__input bordered mb-1"
                      type="text"
                      v-model="fields.id_number"
                      placeholder="NIN or Card ID"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group d-block">
                <label for="id_type">Upload ID Front</label>
                <input
                  name="id_card"
                  class="form__input bordered file-upload mb-1"
                  type="file"
                  @change="handleImageUpload($event, 'proof_of_id')"
                  placeholder="Choose a file or drop it here..."
                />
              </div>
              <div class="form-group">
                <textarea
                  name="address"
                  class="form__input bordered mb-1"
                  cols="30"
                  rows="2"
                  v-model="fields.business_address"
                  placeholder="Address"
                ></textarea>
              </div>
              <div class="form-group d-block">
                <label for="id_type">Uploade Proof of Address</label>
                <input
                  name="address"
                  class="form__input bordered file-upload mb-1"
                  type="file"
                  @change="handleImageUpload($event, 'proof_of_address')"
                  placeholder="Choose a file or drop it here..."
                />
              </div>
              <div class="form-group">
                <button class="btn btn-standard mt-1 w-100" :disabled="loading">
                  {{ loading ? "Please wait" : "Confirm" }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5" v-if="isMerchant && !isMerchantActive">
          <div
            class="
              info-group
              d-flex
              flex-column
              justify-content-center
              align-items-center
              height-70
            "
          >
            <img src="@/assets/buyunits/icons/success-icon.svg" alt="Guest Home" class="img-fluid d-block mx-auto mt-1 mb-1 w-15" />
            <p class="title text-center mb-1">
              Agent Request Sent Successfully
            </p>
            <div class="sub-info-group mt-1">
              <p class="upper-title mb-1">
                Pending Approval...
              </p>
              <p class="sub-title text-center">
                Congratulation your request to upgrade to an Agent has been submitted. You're on your way to start making money when you sell electricity as a BuyUnits Agent. Please give us some time to reveiw your request within the next 24hours.
              </p>
            </div>
            <div class="info-footer">
              <p class="upper m-0">
                If you would like to talk to us, please call
              </p>
              <p class="contact">
                08068291132
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5" v-if="isMerchant && isMerchantActive">
          <div
            class="
              info-group
              d-flex
              flex-column
              justify-content-center
              align-items-center
              height-70
            "
          >
            <img src="@/assets/buyunits/icons/success-icon.svg" alt="Guest Home" class="img-fluid d-block mx-auto mt-1 mb-1 w-15" />
            <div class="sub-info-group mt-1">
              <p class="upper-title mb-1">
                Agent Account Approved
              </p>
              <p class="sub-title text-center">
                Congratulation your request to upgrade to an Agent has been approved. You're are now able to start making money when you sell electricity as a BuyUnits Agent.
              </p>
            </div>
            <div class="info-footer">
              <p class="upper m-0">
                If you would like to talk to us, please call
              </p>
              <p class="contact">
                08068291132
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BecomeMerchantHelpers from "@/functions/actions/BecomeMerchantRequest";
export default {
  name: "BecomeMerchantIndex",
  components: {},
  computed: {
    ...mapState(["loading"]),
    ...mapGetters(["isMerchant", "isMerchantActive"]),
  },
  mounted() {
    if (this.isMerchant && this.isMerchantActive) {
      this.$router.push({
        name: "DashboardHome",
      });
    }
  },
  data() {
    return {
      discos: [],
      fields: {
        bvn: "",
        id_type: "",
        id_number: "",
        proof_of_id: "",
        business_address: "",
        proof_of_address: "",
        business_name: "",
        business_phone: "",
        business_email: "",
        business_website: "",
      },
    };
  },
  methods: {
    async handleImageUpload(e, name) {
      let image = e.target.files[0];
      this.createBase64Image(image, name);
    },
    createBase64Image(fileObject, name) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fields[name] = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    async handleSubmit() {
      if (this.validateRequest()) {
        const response = await BecomeMerchantHelpers.upgradeAccount(
          this.fields
        );
        if (response.status) {
          this.showAlert("Success", "Agent request has been submitted successfully", "success");
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    validateRequest() {
      if (this.fields.business_name) {
        if (this.fields.business_phone) {
          if (this.fields.business_email) {
            if (this.fields.bvn) {
              if (this.fields.id_type) {
                if (this.fields.id_number) {
                  if (this.fields.proof_of_id) {
                    if (this.fields.business_address) {
                      if (this.fields.proof_of_address) {
                        return true;
                      } else {
                        this.showAlert(
                          "Error occured",
                          "Please upload proof of address",
                          "error"
                        );
                      }
                    } else {
                      this.showAlert(
                        "Error occured",
                        "Please enter address",
                        "error"
                      );
                    }
                  } else {
                    this.showAlert(
                      "Error occured",
                      "Please upload proof of ID",
                      "error"
                    );
                  }
                } else {
                  this.showAlert(
                    "Error occured",
                    "Please enter ID number",
                    "error"
                  );
                }
              } else {
                this.showAlert(
                  "Error occured",
                  "Please select ID type",
                  "error"
                );
              }
            } else {
              this.showAlert("Error occured", "Please enter BVN", "error");
            }
          } else {
            this.showAlert(
              "Validation Error",
              "Please enter business email address",
              "error"
            );
          }
        } else {
          this.showAlert(
            "Validation Error",
            "Please enter business phone number",
            "error"
          );
        }
      } else {
        this.showAlert(
          "Validation Error",
          "Please enter Business Name",
          "error"
        );
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>

<style scoped>
.form-information h2 {
  font-size: 35px;
  text-align: center;
}
.form-information form .form-group select.form-control,
.form-information form .form-group input {
  width: 100%;
}
.form-information p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
.form-group label {
  font-size: 14px;
  color: #3A3735;
}
.form-group select {
  border: 1px solid #CBCBCB;
}
textarea.form__input.bordered {
  height: auto;
}
.form-group input.file-upload {
  height: auto;
  padding: 0px;
}
.info-group {
  text-align: center;
  font-family: "Maven Pro", sans-serif;
}
.info-group p.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
}
.info-group p.upper-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
}
.info-group p.sub-title {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #333333;
}
.info-footer p.upper {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.info-footer p.contact {
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
</style>