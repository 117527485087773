<template>
    <section class="about section section--no-pt position-relative">
        <div class="container">
            <!-- <img src="@/assets/buyunits/testimonials/left-icon.svg" alt="icon-left" class="img-fluid d-flex mx-auto icons icon-left">
            <img src="@/assets/buyunits/testimonials/right-icon.svg" alt="icon-right" class="img-fluid d-flex mx-auto icons icon-right"> -->
            <div class="row about__first-row" style="align-items: center">
                <div class="col">
                    <div class="testimonial-carousel owl-carousel">
                        <div class="testimonial-carousel__item" v-for="testimony in testimonials" :key="testimony.id">
                            <div class="card-style">
                                <p class="small">
                                    {{ testimony.description }}
                                </p>
                                <div class="d-flex justify-content-start align-items-center">
                                    <img v-lazy="getImage(testimony.image)" :alt="testimony.alt" class="testimonial-image">
                                    <div class="d-flex justify-content-start flex-column ml-1">
                                        <p class="m-0 fw-bold">
                                            {{ testimony.name}}
                                        </p>
                                        <p class="m-0 small">
                                            {{ testimony.title}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "TestimonialSection",
    computed: {},
    data() {
        return {
            testimonials: [
                {
                    id: 1,
                    description: "I use buyunits all the time. And I have never had any reason to contact customer service, because their service works as expected. I Would always recommend Buyunits",
                    name: "Bridget Amobi",
                    title: "CEO at Havilah Wardrobe",
                    image: 'jennifer.png',
                    alt: 'Jennifer Logo'
                },
                {
                    id: 2,
                    description: "Buyunits offer the best commissions to merchants which makes me earn more than other agents around me. Use buy units if your want to become an agent",
                    name: "Hammed Olalekan",
                    title: "Agent at Shoprite NG",
                    image: 'adams.png',
                    alt: 'Adams Logo'
                },
            ]
        }
    },
    methods: {
        getImage(icon) {
            return require(`@/assets/buyunits/testimonials/${icon}`)
        }
    }
}
</script>
<style scoped>   
.about__first-row {
  margin: 100px auto 40px;
}
.about__first-row .icons {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  margin: auto;
}
.about.section .icons {
    position: absolute;
    z-index: 2;
}
.about.section .icons.icon-left {
    left: 2%;
    top: 40%;
    transform: translate(3%, -50%);
}
.about.section .icons.icon-right {
    right: 2%;
    top: 40%;
    transform: translate(3%, -50%);
}
@media (max-width: 960px) {
    .section {
        padding: 0;
    }
}
</style>