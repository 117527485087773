<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row justify-content-start align-items">
        <router-link to="/">
          <img src="@/assets/buyunits/icons/guest-home.svg" alt="Guest Home" class="img-fluid" />
        </router-link>
      </div>
      <div class="row justify-content-center align-items-center mt-1">
        <div class="col-12 col-sm-12 col-md-10 col-lg-6">
          <Order-Form />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import OrderForm from "@/components/Service/OrderForm";
export default {
  name: "PurchaseView",
  components: {
    OrderForm,
  },
  computed: {
    ...mapState(["loading", "phone_number"]),
  },
  beforeMount() {
    this.$store.dispatch('toggleSteps', 1);
    this.$store.dispatch('updateCurrentStep', 1);
    this.$store.dispatch("clearTransactionDetails");
  },
  mounted() {
    if (!this.isUserLoggedIn && !this.phone_number) {
      this.$router.push({
        name: "Home",
      });
    }
  },
  beforeUnmount() {
    this.$store.dispatch('toggleSteps', 0);
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 100px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
</style>