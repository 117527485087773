<template>
  <div v-if="!alternatePayment">
    <h2 class="title text-center mb-2">Select Payment Method</h2>
    <div
      class="payment-options"
      v-for="option in paymentOptions"
      :key="option.id"
      @click="makeSelection(option.code)"
    >
      <div class="group">
        <img :src="getImage(option.icon)" alt="Card icon" class="img-fluid" />
        <p>{{ option.title }}</p>
        <img
          src="@/assets/buyunits/icons/bi_checked_icon.svg"
          alt="Checked Icon"
          class="img-fluid check-mark"
          v-if="option.code == fields.payment_method"
        />
      </div>
    </div>
    <div class="password-input" v-if="fields.payment_method == 'wallet'" :class="[fields.payment_method === 'wallet' && 'mt-1']">
      <input :type="[reveal ? 'text' : 'password']" class="form__input bordered"  placeholder="Password" v-model="fields.password" />
      <i :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']" @click.prevent="revealPassword"></i>
    </div>
    <button class="btn btn-standard w-100 mt-1" :disabled="loading || fields.payment_method == ''" @click.prevent="handleSubmit()">
      {{
        loading
          ? "Please wait"
          : `Pay &#8358;${getDetails && getDetails.amount && $globalFunc.currency(getDetails.amount)}`
      }}
    </button>
  </div>
  <div v-else>
    <Alternate-Payment :amount="getDetails.amount" :transaction_id="fields.transaction_id" :type="fields.payment_method" :data="alternatePaymentData" @close="disableAlternatePayment" />
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ElectricityHelpers from "@/functions/actions/ElectricityRequest";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
import AlternatePayment from "@/components/Payment/AlternatePayment";
export default {
  name: "PaymentIndex",
  components: {
    AlternatePayment,
  },
  computed: {
    ...mapState(["isUserLoggedIn", "loading"]),
    ...mapGetters(["isMerchant", "getDetails", "isMerchantActive"]),
    paymentOptions() {
      const options = this.options.filter((option) => option.status);
      if (!this.isUserLoggedIn) {
        return options.filter((c) => c.available.indexOf("guest") !== -1 && this.canHandleAmount(c.range));
      } else if (this.isUserLoggedIn && !this.isMerchant) {
        return options.filter((c) => c.available.indexOf("user") !== -1 && this.canHandleAmount(c.range));
      } else if (this.isUserLoggedIn && this.isMerchant) {
        if (this.isMerchantActive) {
          return options.filter((c) => c.available.indexOf("merchant") !== -1 && this.canHandleAmount(c.range));
        }
        return options.filter((c) => c.available.indexOf("user") !== -1 && this.canHandleAmount(c.range));
      } else {
        return [];
      }
    },
  },
  created() {
    if (process.env.VUE_APP_PAYMENT_MODE === "1") {
      let paystackScript = document.createElement('script')
      paystackScript.setAttribute('src', 'https://js.paystack.co/v1/inline.js')
      document.head.appendChild(paystackScript)
    } else if (process.env.VUE_APP_PAYMENT_MODE === "2") {
      let squadcoScript = document.createElement('script')
      squadcoScript.setAttribute('src', 'https://checkout.squadco.com/widget/squad.min.js')
      document.head.appendChild(squadcoScript)
    } else {
      let flutterwaveScript = document.createElement('script')
      flutterwaveScript.setAttribute('src', 'https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js')
      document.head.appendChild(flutterwaveScript)
    }
  },
  mounted() {
    this.fields.transaction_id = this.getDetails && this.getDetails.transaction_id
  },
  data() {
    return {
      reveal: false,
      alternatePayment: false,
      alternatePaymentData: {},
      fields: {
        payment_method: "",
        password: "",
      },
      options: [
        {
          id: 1,
          title: "Pay With Card",
          icon: "bi_card-icon.svg",
          code: "card",
          available: ["guest", "user"],
          range: 0, // default is 2500
          status: true,
        },
        {
          id: 2,
          title: "Pay With Wallet",
          icon: "bi_wallet_icon.svg",
          code: "wallet",
          available: ["user", "merchant"],
          range: 0,
          status: true,
        },
        {
          id: 3,
          title: "Pay With USSD",
          icon: "bi_ussd_icon.svg",
          code: "ussd",
          available: ["guest", "user"],
          range: 0,
          status: true,
        },
        {
          id: 4,
          title: "Pay With Bank Transfer",
          icon: "bi_bank_icon.svg",
          code: "bank_transfer",
          available: ["guest", "user"],
          range: 0,
          status: true,
        },
      ],
    };
  },
  methods: {
    revealPassword() {
      this.reveal = !this.reveal;
    },
    canHandleAmount(range) {
      if (range === 0) {
        return true;
      }
      return this.getDetails && this.getDetails.amount < range;
    },
    makeSelection(code) {
      this.fields.payment_method = code;
    },
    getImage(icon) {
      return require(`@/assets/buyunits/icons/${icon}`);
    },
    handleSubmit() {
      if (this.fields.payment_method === "card") {
        if (process.env.VUE_APP_PAYMENT_MODE === "1") {
          this.payWithPaystack();
        } else if (process.env.VUE_APP_PAYMENT_MODE === "2") {
          this.payWithSquadco();
        } else {
          this.payWithRave();
        }
      } else if (this.fields.payment_method === "wallet") {
        if (this.fields.password) {
          this.vendServiceInfo();
        } else {
          this.showAlert('Wallet Approval', 'Kindly enter account password', 'warn');
        }
      } else if (this.fields.payment_method === "ussd" || this.fields.payment_method === "bank_transfer") {
        // handle ussd and bank transfer
        // this.generatePaymentCode(); // revert to this when ready to use iRecharge
        if (process.env.VUE_APP_PAYMENT_MODE === "1") {
          this.payWithPaystack();
        } else if (process.env.VUE_APP_PAYMENT_MODE === "2") {
          this.payWithSquadco();
        } else {
          this.payWithRave();
        }
      } else {
        this.showAlert('Payment Method', 'Kindly select a payment method', 'warn');
      }
    },
    disableAlternatePayment() {
      this.alternatePayment = false
    },
    payWithPaystack() {
      this.$store.dispatch("setLoading", true);
      var that = this;
      var handler = PaystackPop.setup({
        key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
        email: that.getDetails.email ? that.getDetails.email : process.env.VUE_APP_DEFAULT_EMAIL,
        amount: that.getDetails.amount * 100,
        metadata: {
          custom_fields: [
            {
              customer_phone: that.getDetails.phone,
              customer_email: that.getDetails.email,
              transaction_id: that.getDetails.transaction_id
            },
          ],
        },
        callback: function(response) {
          if (response.status == "success" && response.message == "Approved") {
            that.fields.payment_ref = response.reference ? response.reference : response.trxref
            that.vendServiceInfo()
          } else {
            that.showAlert('Payment Error', 'Your payment could not be processed', 'warn')
            that.$store.dispatch("setLoading", false);
          }
        },
        onClose: function() {
          that.$store.dispatch("setLoading", false);
        },
      });
      handler.openIframe();
    },
    payWithSquadco() {
      this.$store.dispatch("setLoading", true);
      var that = this;
      const squadInstance = new squad({
        onClose: () => that.$store.dispatch("setLoading", false),
        onLoad: () => {},
        onSuccess: (response) => {
          that.fields.payment_ref = response.transaction_ref
          setTimeout(() => {
            squadInstance.close()
            that.vendServiceInfo()
          }, 1500);
        },
        key: process.env.VUE_APP_SQUADCO_PUBLIC_KEY,
        email: that.getDetails.email ? that.getDetails.email : process.env.VUE_APP_DEFAULT_EMAIL,
        amount: that.getDetails.amount * 100,
        currency_code: "NGN",
        meta: {
          custom_fields: {
            customer_phone: that.getDetails.phone,
            customer_email: that.getDetails.email,
            transaction_id: that.getDetails.transaction_id
          },
        },
      });
      squadInstance.setup();
      squadInstance.open();
    },
    async vendServiceInfo() {
      const payment_method = this.fields.payment_method === "ussd" || this.fields.payment_method === "bank_transfer" ? "card" : this.fields.payment_method
      const response = await ElectricityHelpers.vendElectricity({
        ...this.fields, 
        payment_method,
        transaction_id: this.fields.transaction_id.toString()
      });
      if (response.status) {
        // payment was successful, route user to receipt page
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    async generatePaymentCode() {
      const response = await GeneralHelpers.generatePaymentCode({
        transaction_id: this.fields.transaction_id.toString(),
        payment_method: this.fields.payment_method,
      });
      if (response.status) {
        this.alternatePaymentData = response.data;
        this.alternatePayment = true;
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
h2.title {
  font-size: 18px;
}
.payment-options {
  margin-top: 10px;
  border: 0.5px solid #9e9e9e;
  box-shadow: 0px 3px 2px 0px #00000033;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}
.payment-options:hover {
  border: 1px solid #28aa63;
  box-shadow: 0px 3px 2px 0px #28aa63;
}
.payment-options:hover p {
  color: #28aa63;
}
.payment-options .group {
  display: flex;
  align-items: center;
  position: relative;
}
.payment-options .group p {
  margin-bottom: 0;
  margin-left: 15px;
  font-size: 16px;
  font-family: "Poppins";
}
.payment-options .group img:first-child {
  padding-left: 10px;
}
.payment-options .group img:last-child {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0%, -50%);
}

@media (min-width: 1199px) {
  .password-input i, .select-input i {
    transform: translate(0%, -50%);
  }
}
</style>