<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-start">
            <img
              src="@/assets/buyunits/icons/dashboard/dashboard-active.svg"
              class="img-fluid d-block mr-1"
            />
            <div class="left">
              <h2 class="dashboard-title">Dashboard</h2>
              <p class="small m-0 sub-header">
                <strong>Merchant Name:</strong> {{ isMerchant && isMerchant.business_name }}
              </p>
              <p class="small m-0 sub-header">
                <strong>Merchant ID:</strong> {{ isMerchant && isMerchant.merchant_id }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-lg-6 mt-2">
          <!-- Wallet Amount -->
          <div class="row justify-content-start align-items-start mb-1">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              <Wallet-Card type="wallet"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6" v-if="!loading && getDashboardInfo">
              <Wallet-Card type="profit" :payload="getDashboardInfo.wallet_commission"/>
            </div>
          </div>
          <!-- End Wallet Amount -->
          <!-- Transaction Count -->
          <div class="row justify-content-start align-items-start mb-1">
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              <div class="dashboard-card mb-1">
                <p class="card-title m-0">Transaction Count</p>
                <p class="card-volume m-0">{{ getDashboardInfo && getDashboardInfo.transaction_count && $globalFunc.currency(getDashboardInfo.transaction_count) }}</p>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
              <div class="dashboard-card mb-1">
                <p class="card-title m-0">Transaction Volume</p>
                <p class="card-volume m-0">&#8358;{{ getDashboardInfo && getDashboardInfo.transaction_volume && $globalFunc.currency(getDashboardInfo.transaction_volume) }}</p>
              </div>
            </div>
          </div>
          <!-- End Transaction Count -->
          <!-- Transaction Graph -->
          <div class="row justify-content-start align-items-start mb-2">
            <div class="col-lg-12">
              <div class="dashboard-card special mb-1">
                <p class="transaction-volume mb_5">Transaction Volume for the last 7 days</p>
                <p class="transaction-volume-amount m-0">&#8358;{{ last7daysAmount && $globalFunc.currency(last7daysAmount) }}</p>
                <div id="container"></div>
              </div>
            </div>
          </div>
          <!-- End Transaction Graph -->
        </div>
        <div class="col-lg-6">
          <Commissions-Card />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DashboardHelpers from "@/functions/actions/DashboardRequest";
import WalletCard from "@/components/Dashboard/WalletCard";
import CommissionsCard from "@/components/Dashboard/CommissionsCard";
export default {
  name: "Dashboard",
  components: { WalletCard, CommissionsCard },
  computed: {
    ...mapState(["loading", "phone_number", "isUserLoggedIn"]),
    ...mapGetters(["isMerchant", "isMerchantActive", "getDashboardInfo", "getCommissions", "walletBalance"]),
    last7daysAmount() {
      return this.getDashboardInfo && Object.values(this.getDashboardInfo.last_7days).reduce((a, b) => a + b, 0);
    },
  },
  created() {
    let anyChart = document.createElement('script')
      anyChart.setAttribute('src', 'https://cdn.anychart.com/releases/8.11.0/js/anychart-base.min.js')
      document.head.appendChild(anyChart)
  },
  beforeMount() {
    if (window.width <= 991) {
      this.$store.dispatch("toggleSidebar", 0);
    }
  },
  mounted() {
    if (this.isMerchant && !this.isMerchantActive) {
      this.$router.push({
        name: "SellElectricity",
      });
    }
    this.fetchDashboardInfo();
  },
  data() {
    return {
      dashboardInfo: {}
    };
  },
  methods: {
    async fetchDashboardInfo() {
      const response = await DashboardHelpers.dashboardInfo()
      if (response.status) {
        this.dashboardInfo = response.dashboard;
        this.$store.dispatch("setDashboardInfo", response.dashboard);
        this.drawChart();
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    drawChart() {
      // convert object to array of objects
      var that = this;
      var dataArray = Object.keys(this.dashboardInfo.last_7days).map(function (key, value) {
        return {
          x: that.$globalFunc.convertDateTimetoHuman(key),
          value: that.dashboardInfo.last_7days[key]
        };
      });
      //  Creates Bar chart.
      var chart = anychart.bar(dataArray);
      // set the padding between bars
      chart.barsPadding(-0.5);
      chart.container('container');
      chart.draw();
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
.sub-header {
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin: 0px !important;
}
.dashboard-card .card-title {
  font-family: "Maven Pro", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}
.dashboard-card .card-volume {
  font-family: "Maven Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #333333;
}
.dashboard-card .transaction-volume {
  font-family: "Maven Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #272B3F;
}
.dashboard-card .transaction-volume-amount {
  font-family: "Maven Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #272B3F;
}
#container {
  width: 100%;
  height: 30vh;
  margin: 0;
  padding: 0;
}
</style>