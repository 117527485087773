<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row" style="align-items: center">
        <div class="col-lg-12">
          <div class="block-header">
            <h2 class="title title--black title--medium">Privacy Policy</h2>
          </div>
          <p>
            This privacy policy describes your rights regarding our collection, use, storage, sharing and protection of your personal information, which you provide for membership on the platform. When you complete any form, speak with our staff or submit any details to us, we will collect, use and disclose your personal information in the manner described in this Privacy Policy. We respect the privacy of our online guests and registered users (“Users”) as such we will take reasonable steps to protect your information Personal Information, we hold with description of the types of Personal Information we collect, the purposes for which we collect that Personal Information, the other parties with whom we may share it and the measures we take to protect the security of the data.
          </p>
          <p>
            <strong>OVERVIEW</strong><br />
            Buy Units (“online energy purchase platform”, “Platform”, “Service”) is an electronic distribution platform that allows individuals make prepaid and postpaid electricity bill payments. For the purpose of this policy, "you" or "your" means the users of this platform and its services, "we", "us" or "our" refers to Buy Units Ltd. This Privacy Policy statement is to provide all persons and organizations whose Personal Information we hold with description of the types of Personal Information we collect, the purposes for which we collect that Personal Information, the other parties with whom we may share it and the measures we take to protect the security of the data.
          </p>
          <p>
            <strong>INFORMATION WE COLLECT</strong><br />
            The information we collect about you may be: Personal data such as name, email address, telephone number, meter number. Further information such as your Bank Verification Number may be required in order for you to be able to sign up on the platform. You may change your personal identifiable information on Buy Units at any time by contacting Customer Service or accessing your account profile online. Some of such information will be updated immediately, while others may require further verification before it is updated.<br />
            When you use Buy Units from a computer, mobile phone, or other device, we may collect information from that device regarding your browser type, location, and IP address, as well as the pages you visit for security purposes. When using our mobile applications, we ask for access to your phone contacts so you may more easily make payments to people in your phone contacts via the mobile application. We also give you the additional option to opt-in for your contacts to be uploaded to our servers. By uploading your contacts to our servers, you can now access your phone contacts on other Buy Units channels for ease of transacting to people in your contacts. We will only upload to our servers if you give us the additional explicit permission. We will not share your contacts with any 3rd party. How we get your Personal Information and why we have it The Personal Information we have about you is directly made available to us when you:<br />
          </p>
          <ol>
            <li>Sign up for on Buy Units Account;</li>
            <li>Use any of our services;</li>
            <li>Fill our online forms;</li>
            <li>Contact us or send us any correspondence</li>
            <li>Respond to calls we make to you and correspondence we send to you;</li>
            <li>The lawful basis we rely on for processing your Personal Information are:</li>
            <li>Your consent; Where you agree to us collecting your Personal Information by using our Services.</li>
            <li>We have a contractual obligation: Without your Personal Information, we cannot provide our Services to you.</li>
          </ol>
          <p>
            We have a legal obligation: To ensure we are fully compliant with all applicable financial legislations such as Anti-Money Laundering and Counter Terrorist Financing Laws, we must collect and store your Personal Information. We protect against fraud by checking your identity with your Personal Information
          </p>
          <p>
            <strong>INFORMATION WE COLLECT ABOUT YOU FROM THIRD PARTIES</strong><br />
            Sometimes we may collect information about you from third parties such as credit/debit Card Company or processor, bank, social media sites and payment gateway. The information we receive varies and is controlled by the third-party site or application. By associating an account managed by a third party with your Buy Units account and authorizing Buy Units to have access to this information, you agree that Buy Units may collect, store and use this information in accordance with this Privacy Policy.
          </p>
          <p>
            <strong>HOW WE USE THE INFORMATION WE COLLECT</strong><br />
            All the information we collect from you and about you are used in the following ways: To operate and administer your Buy Units account securely. To carry out your instructions to make payments and undertake your transactions using our service and send you notification of the transaction and payment. To notify you about changes to our terms and policies. To communicate with you on updates, complaints and feedback. To improve our internal customer service training. For Quality Assurance purposes. For customer service including answering questions and responding to feedback and complaints. To enhance security to our Service and the Buy Units account.
          </p>
          <p>
            <strong>HOW WE STORE AND PROTECT YOUR INFORMATION</strong><br />
            We store and process your information on our server. We protect your information from illegal and unauthorized access through the use of physical, technical and administrative security measures some of which is the use of firewalls, data encryption, physical access controls to our database and information access authorization controls. To effectively protect your information, do not share your card details or email account details with anyone.
          </p>
          <p>
            <strong>HOW WE SHARE YOUR INFORMATION WITH THIRD PARTY</strong><br />
            We disclose your information with third party only in ways described in this policy. We do not and will never sell your information to any third party. We require these third parties to not use your information for any other purpose. We may disclose your information such as payment details, name etc. for payment processing. We may disclose your information to a prospective shareholder of Buy Units. We may disclose your information to the police or other law enforcement body or regulatory body as long as a court order is provided for such information. We may disclose or share your Personal Information with third parties which include our affiliates, employees, officers, service providers, agents, suppliers, subcontractors as may be reasonably necessary for the purposes set out in this policy. Third party social media sites that you have chosen to share your membership information with. Example, if you wish to have your membership of the Buy Units Platform shared on Facebook, twitter, Instagram, WhatsApp, etc
          </p>
          <p>
            <strong>WHAT WE SHARE WITH THIRD PARTY</strong><br />
            We do not sell your personal information to any third party We may share certain information about you with a third party that you extend that right or privilege to. In the event Buy Units is bought or we have new investors, your personal information will be provided to the new buyer or investor.
          </p>
          <p>
            <strong>YOUR RESPONSIBILITY</strong><br />
            You are solely responsible for entering correct customer details into the platform before transferring money, paying bills or purchasing virtual products. You are responsible for any charge that the use of mobile internet connection/Wi-Fi may incur while using Buy Units You are responsible for keeping the Buy Units plug / mobile application/ desktop application up-to-date.
          </p>
          <p>
            <strong>DATA SECURITY AND RETENTION</strong><br />
            Buy Units recognizes the importance of protecting data from un-authorised access and data corruption and the Company has:
          </p>
          <ul class="mb-1 mt_5">
            <li>Developed security measures including but not limited to protecting systems from hackers</li>
            <li>Set up firewalls and protected all email systems</li>
            <li>Set up measures to store data securely with access to specific authorised individuals.</li>
            <li>Deployed data encryption technologies</li>
            <li>Developed organisational policy for handling personal data and other sensitive or confidential data.</li>
          </ul>
          <p>
            <strong>UPDATES, MODIFICATIONS &amp; AMENDMENTS</strong><br />
            We may need to update, modify or amend our Privacy Policy as our technology evolves and as required by law. We reserve the right to make changes to this Privacy Policy from time to time and notify Users of material changes. We advise that you check this page often, In the event that modifications have been made to the policy. If a user objects to any of the changes to this Privacy Policy, the user must cease using this Site, or terminate their account in the event an account has been created. If Buy Units becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, we will provide notice before personal information is transferred and becomes subject to a different privacy policy.
          </p>
          <br />
          <p>
            <i>Last updated on: <strong>July 1st, 2022</strong></i>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PrivacyPolicy",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 15px;
  text-align: justify;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
dl, ol, ul {
  margin-bottom: 10px;
  color: #2c2c2c;
  line-height: 30px;
}
ul {
  color: #2c2c2c;
  line-height: 30px;
  margin: 0;
  padding: 0;
  list-style-type: disc;
  margin-block-start: 1em;
  padding-inline-start: 40px;
}
</style>