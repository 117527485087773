<template>
  <div id="overlayer" :style="{'display': loading ? 'block' : 'none'}">
    <span class="loader-overlay" :style="{'display': loading ? 'block' : 'none'}">
      <div class="atbd-spin-dots spin-lg">
        <span class="spin-dot badge-dot dot-primary"></span>
        <span class="spin-dot badge-dot dot-primary"></span>
        <span class="spin-dot badge-dot dot-primary"></span>
        <span class="spin-dot badge-dot dot-primary"></span>
      </div>
    </span>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Preloader",
  computed: {
    ...mapState(["loading"]),
  },
};
</script>
<style scoped>
</style>