import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store";
import MainLayout from '../layout/MainLayout.vue'
import DashboardLayout from '../layout/DashboardLayout.vue'

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: HomeView,
        alias: '/home',
        meta: { pageTitle: 'Home' }
      },
      {
        path: '/pages/purchase',
        name: 'GuestPurchase',
        component: require('../views/PurchaseView.vue').default,
        meta: { pageTitle: 'Purchase Electricity' }
      },
      {
        path: '/pages/purchase/details',
        name: 'GuestPurchaseDetails',
        component: require('../views/PurchaseDetails.vue').default,
        meta: { pageTitle: 'Purchase Electricity Details' }
      },
      {
        path: '/pages/purchase/receipt',
        name: 'GuestPurchaseReceipt',
        component: require('../views/PurchaseReceipt.vue').default,
        meta: { pageTitle: 'Purchase Electricity Receipt' }
      },
      {
        path: "/pages/about",
        name: "about",
        component: require("../views/AboutView.vue").default,
        meta: { pageTitle: 'About Us' }
      },
      {
        path: "/pages/contact",
        name: "contact",
        component: require("../views/ContactView.vue").default,
        meta: { pageTitle: 'Contact Us' }
      },
      {
        path: "/pages/services",
        name: "services",
        component: require("../views/ServicesView.vue").default,
        meta: { pageTitle: 'Purchase Electricity Receipt' }
      },
      {
        path: '/pages/privacy-policy',
        name: 'PrivacyPolicy',
        component: require('../views/PrivacyPolicy.vue').default,
        meta: { pageTitle: 'Privacy Policy' }
      },
      {
        path: '/pages/terms-of-service',
        name: 'TermsOfService',
        component: require('../views/TermsOfService.vue').default,
        meta: { pageTitle: 'Terms of Service' }
      },
    ]
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: 'home',
        name: 'DashboardHome',
        component: require('../views/Dashboard/Index.vue').default,
        meta: { pageTitle: 'Agent Dashboard', requiresAuth: true }
      },
    ]
  },
  {
    path: '/purchase',
    component: DashboardLayout,
    children: [
      {
        path: 'retail',
        name: 'SellElectricity',
        component: require('../views/Dashboard/PurchaseView.vue').default,
        meta: { pageTitle: 'Sell Electricity', requiresAuth: true }
      },
      {
        path: 'retail/details',
        name: 'SellElectricityDetails',
        component: require('../views/Dashboard/PurchaseDetails.vue').default,
        meta: { pageTitle: 'Sell Electricity Details', requiresAuth: true }
      },
      {
        path: 'retail/receipt',
        name: 'SellElectricityReceipt',
        component: require('../views/Dashboard/PurchaseReceipt.vue').default,
        meta: { pageTitle: 'Sell Electricity Receipt', requiresAuth: true }
      },
    ]
  },
  {
    path: '/wallet',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'WalletIndex',
        component: require('../views/Dashboard/Wallet/Index.vue').default,
        meta: { pageTitle: 'Wallet', requiresAuth: true }
      },
    ]
  },
  {
    path: '/transactions',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'TransactionIndex',
        component: require('../views/Dashboard/Transactions/Index.vue').default,
        meta: { pageTitle: 'Transactions', requiresAuth: true }
      },
    ]
  },
  {
    path: '/saved-cards',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'SavedCardsIndex',
        component: require('../views/Dashboard/SavedCards/Index.vue').default,
        meta: { pageTitle: 'Saved Card', requiresAuth: true }
      },
    ]
  },
  {
    path: '/meter-numbers',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'MeterNumbersIndex',
        component: require('../views/Dashboard/MeterNumbers/Index.vue').default,
        meta: { pageTitle: 'Meter Numbers', requiresAuth: true }
      },
    ]
  },
  {
    path: '/unit-calculator',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'UnitCalculatorIndex',
        component: require('../views/Dashboard/UnitCalculator/Index.vue').default,
        meta: { pageTitle: 'Unit Calculator', requiresAuth: true }
      },
    ]
  },
  {
    path: '/request-utility-bill',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'RequestUtilityBillIndex',
        component: require('../views/Dashboard/RequestUtilityBill/Index.vue').default,
        meta: { pageTitle: 'Request Utility Bill', requiresAuth: true }
      },
    ]
  },
  {
    path: '/become-merchant',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'BecomeMerchantIndex',
        component: require('../views/Dashboard/BecomeMerchant/Index.vue').default,
        meta: { pageTitle: 'Become Merchant', requiresAuth: true }
      },
    ]
  },
  {
    path: '/account-settings',
    component: DashboardLayout,
    children: [
      {
        path: 'index',
        name: 'AccountSettingsIndex',
        component: require('../views/Dashboard/AccountSettings/Index.vue').default,
        meta: { pageTitle: 'Account Settings', requiresAuth: true }
      },
      {
        path: 'api-keys',
        name: 'AccountSettingsApiKeys',
        component: require('../views/Dashboard/AccountSettings/ApiKeys.vue').default,
        meta: { pageTitle: 'API Keys', requiresAuth: true }
      },
    ]
  },
  {
    path: '/verification',
    name: 'UserVerification',
    component: require('../views/UserVerification.vue').default,
    beforeEnter: (to, from, next) => {
      if (store.state.isUserLoggedIn && !store.getters.isUserActive) {
        next();
      } else {
        next('/home');
      }
    },
    meta: { pageTitle: 'User Verification Page' }
  },
  { 
    path: '/:pathMatch(.*)*', 
    beforeEnter: (to, from, next) => { 
      next('/404') 
    } 
  },
  {
    path: '/404',
    component: require('../views/404.vue').default,
    meta: { pageTitle: '404 Page' }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next("/home");
  } else if (to.matched.some((record) => record.meta.requiresAuth) && token) {
    if (!store.getters.isUserActive) {
      next("/verification");
    } else {
      next();
    }
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
  }
  next();
});

router.afterEach((to, from) => {
  document.title = `${process.env.VUE_APP_TITLE} | ${to.meta.pageTitle}`;
  window.scroll(0, 0);
});

export default router;
