<template>
  <div class="dashboard-card">
    <h2 class="title">My Commissions</h2>
    <ul
      class="commissions-list"
      v-if="getCommissions && getCommissions.length > 0"
    >
      <li class="commission-list-item" v-for="commission in getCommissions" :key="commission.provider">
        <strong>{{ `${commission.provider}:` }}</strong> {{ `${commission.percentage}%` }}  {{ commission['value'] > 0 ? `+ NGN ${commission['value']}` : '' }}
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CommissionsCard",
  computed: {
    ...mapGetters(["getCommissions"]),
  },
};
</script>
<style scoped>
h2.title {
  font-family: "Maven Pro", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #3a3735;
}
</style>