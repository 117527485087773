<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row" style="align-items: center">
        <div class="col-lg-12">
          <div class="block-header">
            <h2 class="title title--black title--medium">Contact Us</h2>
          </div>
          <p>
            Did you encounter any problem while using our platform? Or maybe you would like to discuss business, please contact us:
          </p>
          <p class="mt-2">
            Send an email to - <a :href="`mailto:${email_info}`">{{ email_info }}</a> or <a :href="`mailto:${email_support}`">{{ email_support }}</a> <br> Or call - <a :href="`tel: ${mobile}`">{{ mobile }}</a>
          </p>
          <p class="mt-2">
            You can also send us direct messages on any of our social media handles.
          </p>
        </div>
      </div>
      <div class="row justify-content-start align-items mt-2">
        <router-link to="/">
          <img src="@/assets/buyunits/icons/guest-home.svg" alt="Guest Home" class="img-fluid" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ContactPage",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {
      email_info: process.env.VUE_APP_EMAIL_INFO,
      email_support: process.env.VUE_APP_EMAIL_SUPPORT,
      mobile: process.env.VUE_APP_HELP_LINE,
    };
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
</style>