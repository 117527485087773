<template>
  <div>
    <footer class="footer font-2" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6">
            <router-link to="/" class="logo">
              <h2 class="title title--black title--small mb-1">
                <img src="assets/img/logo/buyunits-colored-logo.svg" alt="logo" style="width: 180px" class="img-fluid">
              </h2>
            </router-link>
            <div class="footer__text mb-1">
              <p class="small">We provide our clients with solutions that are streamlined to suit your diverse technology needs and achieve your business objectives.</p>
            </div>
            <ul class="social-list justify-content-start mb-1">
              <li class="social-list__item">
                <a href="#" aria-label="Facebook logo" class="social-list__link" @click.prevent="handleSocialLinks('facebook')">
                  <img v-lazy="facebookImage" alt="facebook Image" class="img-fluid mx-auto d-block" />
                </a>
              </li>
              <li class="social-list__item">
                <a href="#" aria-label="Twitter logo" class="social-list__link" @click.prevent="handleSocialLinks('twitter')">
                  <img v-lazy="twitterImage" alt="twitter Image" class="img-fluid mx-auto d-block" />
                </a>
              </li>
              <li class="social-list__item">
                <a href="#" aria-label="Instagram logo" class="social-list__link" @click.prevent="handleSocialLinks('instagram')">
                  <img v-lazy="instagramImage" alt="instagram Image" class="img-fluid mx-auto d-block" />
                </a>
              </li>
            </ul>
          </div>
          <div class="col-4 col-lg-2 offset-lg-1 col-md-2">
            <h2 class="title title--black footer-title--small mb_5">Company</h2>
            <ul class="footer--menu">
              <li class="footer--menu__item">
                <router-link class="footer--menu__link" to="/pages/about">
                  About Us
                </router-link>
              </li>
              <li class="footer--menu__item">
                <a class="footer--menu__link" href="#" onclick="event.preventDefault()">
                  FAQs
                </a>
              </li>
              <li class="footer--menu__item">
                <a class="footer--menu__link" href="#" onclick="event.preventDefault()">
                  Blog
                </a>
              </li>
              <li class="footer--menu__item">
                <router-link class="footer--menu__link" to="/pages/contact">
                  Contact Us
                </router-link>
              </li>
              <li class="footer--menu__item">
                <a href="https://documenter.getpostman.com/view/25805034/2s935uFztu" class="footer--menu__link" target="_blank">
                  API Documentation
                </a>
              </li>
            </ul>
          </div>
          <div class="col-8 col-lg-4 col-md-4">
            <div class="d-flex justify-content-start align-items-center mb_5">
              <img v-lazy="homeImage" alt="home icon" class="d-flex img-fluid mr-1" />
              <h2 class="title title--black footer-title--small">Address</h2>
            </div>
            <p class="small">{{ address }}</p>
            <p class="small">
              <span>Mobile:</span> <a :href="`tel: ${mobile}`">{{ mobile }}</a>
            </p>
            <!-- <p class="small">
              <span>Mobile:</span> +234 803700 4762, +2349058590309 
            </p> -->
            <p class="small">
              <span>Email:</span> <a :href="`mailto:${email_info}`">{{ email_info }}</a> <br />
              <a :href="`mailto:${email_support}`">{{ email_support }}</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
    <div class="copyright font-2">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between copyright-content">
          <div class="left title--black small">
            &copy; {{ new Date().getFullYear()  }} Buyunits, All Rights Reserved.
          </div>
          <div class="right small">
            <ul class="d-flex align-items-center">
              <li>
                <router-link to="/pages/terms-of-service">
                  Terms of Use
                </router-link>
              </li>
              <li>
                <router-link to="/pages/privacy-policy">
                  Privacy Policy
                </router-link>
              </li>
              <li>
                <router-link to="/" onclick="event.preventDefault()">
                  Google Map
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-footer",
  data() {
    return {
      facebookImage: require("@/assets/buyunits/icons/facebook-black.svg"),
      twitterImage: require("@/assets/buyunits/icons/twitter-black.svg"),
      linkedinImage: require("@/assets/buyunits/icons/linkedin-black.svg"),
      instagramImage: require("@/assets/buyunits/icons/instagram-black.svg"),
      homeImage: require("@/assets/buyunits/icons/home.svg"),
      address: process.env.VUE_APP_ADDRESS,
      email_info: process.env.VUE_APP_EMAIL_INFO,
      email_support: process.env.VUE_APP_EMAIL_SUPPORT,
      mobile: process.env.VUE_APP_HELP_LINE,
    };
  },
  methods: {
    handleSocialLinks(social) {
      switch (social) {
        case "facebook":
          window.open("https://www.facebook.com/BuyUnitsNG/", "_blank");
          break;
        case "twitter":
          window.open("https://twitter.com/buyunitsng", "_blank");
          break;
        case "instagram":
          window.open("https://www.instagram.com/buyunitsng/", "_blank");
          break;
      }
    },
  }
};
</script>
<style scoped>
p {
  margin-bottom: 0;
}
.fontello-icon {
  color: rgb(237 94 48);
}
.footer {
  padding: 40px 0 30px;
  border-top: 1px solid #ccc;
}
.footer--menu__link {
  font-size: 14px;
  color: #000;
}
li.footer--menu__item {
  margin-bottom: 12px;
}
p.small span {
  color: #28AA63;
}
.copyright {
  padding: 15px 0px;
  border-top: 1px solid #ccc;
} 
.copyright .right li {
  margin-right: 1rem;
}
@media (max-width: 991.98px) {
  .footer__block {
    margin-bottom: 20px;
  }
}
@media (max-width: 575.98px) {
  .footer {
    padding: 20px 0 10px;
  }
  .footer .logo {
    margin-bottom: 10px;
  }
  .footer-menu {
    margin-bottom: 10px;
  }
  .logo__img--big {
    width: 120px;
  }
}
</style>