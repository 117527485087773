<template>
  <header
    class="header"
    :class="[
      servicesRoute === 'purchase' ||
      servicesRoute === 'dashboard' ||
      servicesRoute === 'wallet' ||
      servicesRoute === 'transactions' ||
      servicesRoute === 'saved-cards' ||
      servicesRoute === 'meter-numbers' ||
      servicesRoute === 'unit-calculator' ||
      servicesRoute === 'request-utility-bill' ||
      servicesRoute === 'become-merchant' ||
      servicesRoute === 'account-settings' ||
      servicesRoute === 'pages'
        ? 'sticky'
        : '',
    ]"
  >
    <div class="navbar-left d-flex justify-content-between align-items-center">
      <a href="#" class="sidebar-toggle" aria-label="Header logo" @click.prevent="handleSidebar()" v-if="servicesRoute !== '' && servicesRoute !== 'home' && servicesRoute !== 'pages'">
        <img class="svg" src="@/assets/buyunits/icons/bars.svg" alt="img">
      </a>
      <router-link to="/" class="logo" aria-label="Header logo">
        <div class="logo__img"></div>
      </router-link>
    </div>

    <div class="header__right">
      <ul class="menu m-0">
        <li class="menu__item font-2">
          <router-link to="/pages/services" class="menu__link">
            Services
          </router-link>
        </li>
        <li class="menu__item font-2">
          <router-link to="/pages/about" class="menu__link">
            About
          </router-link>
        </li>
        <li class="menu__item font-2">
          <a href="#" class="menu__link" onclick="event.preventDefault()">Blog</a>
        </li>
        <li class="menu__item font-2">
          <router-link to="/pages/contact" class="menu__link">
            Contact us
          </router-link>
        </li>
        <li class="menu__item font-2" v-if="!isUserLoggedIn">
          <a
            href="#"
            class="menu__link"
            @click.prevent="$store.dispatch('handleModal', 'login')"
            >Login</a
          >
        </li>
        <li class="menu__item font-2" v-if="!isUserLoggedIn">
          <a
            href="#"
            class="menu__link"
            @click.prevent="$store.dispatch('handleModal', 'register')"
            >Register</a
          >
        </li>
        <li class="menu__item font-2" v-if="isUserLoggedIn">
          <a
            href="#"
            class="menu__link"
            @click.prevent="handleDashboardLink()"
            >Dashboard</a
          >
        </li>
        <li class="menu__item font-2" v-if="isUserLoggedIn">
          <a
            href="#"
            class="menu__link"
            @click.prevent="logoutUser()"
            >Logout</a
          >
        </li>
      </ul>
    </div>
    <div class="btn-menu" @click.prevent="$store.dispatch('openSideMenu')">
      <div class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AuthHelpers from "@/functions/actions/AuthRequest";
export default {
  name: "Header",
  computed: {
    ...mapState(["user", "isUserLoggedIn", "collapsed"]),
    ...mapGetters(["isMerchant"]),
    servicesRoute() {
      return this.$route.path.split("/")[1];
    },
  },
  data() {
    return {};
  },
  methods: {
    handleDashboardLink() {
      if (this.isUserLoggedIn) {
        if (this.isMerchant) {
          this.$router.push({ name: "DashboardHome" });
        } else {
          this.$router.push({ name: "SellElectricity" });
        }
      }
    },
    handleSidebar() {
      if (this.collapsed) {
        this.$store.dispatch('toggleSidebar', 0)
      } else {
        this.$store.dispatch('toggleSidebar', 1)
      }
    },
    async logoutUser() {
      if (confirm("Are you sure you want to logout?")) {
        const response = await AuthHelpers.logoutRequest();
        if (response.status) {
          // redirect user to homepage
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>

<style scoped>
a:hover {
  text-decoration: none;
}
.logo {
  top: 0px;
}
.menu__link {
  font-size: 14px;
}
.menu__link.btn-white-border {
  border: 1px solid #fff;
  padding: 10px 15px;
  border-radius: 6px;
}
@media only screen and (max-width: 1400px) {
  .menu__item {
    margin-right: 50px;
  }
  .menu__item:last-child {
    margin-right: 0;
  }
}
</style>
