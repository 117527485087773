<template>
  <div id="app">
    <notifications />
    <Preloader />
    <Login-Modal v-if="modal.login" @close="$store.dispatch('handleModal', 'login')" />
    <Register-Modal v-if="modal.register" @close="$store.dispatch('handleModal', 'register')" />
    <Forgot-Password-Modal v-if="modal.forgotPassword" @close="$store.dispatch('handleModal', 'forgotPassword')" />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Preloader from "./components/Preloader.vue";
import LoginModal from "./components/Auth/LoginModal.vue";
import RegisterModal from "./components/Auth/RegisterModal.vue";
import ForgotPasswordModal from "./components/Auth/ForgotPasswordModal.vue";
export default {
  name: "App",
  computed: {
    ...mapState(["loading", "isUserLoggedIn", "modal"]),
  },
  components: {
    Preloader,
    LoginModal,
    RegisterModal,
    ForgotPasswordModal,
  },
  mounted() {
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.swal2-popup {
  font-size: 0.7rem !important;
}
.error {
  font-size: 12px;
  color: red;
}
.text-black {
  color: #000;
}
a {
  color: #28AA63;
}
a:hover {
  color: #3D76CA;
}
.vue-notification-group {
  z-index: 9999 !important;
  font-family: 'Maven Pro', sans-serif !important;
}
.notification-title {
  margin-bottom: 15px !important;
  font-size: 1rem !important;
}
.notification-content {
  font-size: 14px !important;
}
</style>
