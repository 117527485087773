<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row justify-content-center align-items-center mt-1">
        <!-- -->
        <div class="col-12 col-sm-12 col-md-10 col-lg-6">
          <Order-Form />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import OrderForm from "@/components/Service/OrderForm";
export default {
  name: "DashboardSell",
  components: {
    OrderForm,
  },
  computed: {
    ...mapState(["loading", "phone_number"]),
  },
  beforeMount() {
    this.$store.dispatch('toggleSteps', 1);
    this.$store.dispatch('updateCurrentStep', 1);
    this.$store.dispatch("clearTransactionDetails");
  },
  mounted() {},
  beforeUnmount() {
    this.$store.dispatch('toggleSteps', 0);
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
</style>