<template>
  <div>
    <!-- Static section -->
		<Static-Section />
    <!-- Partner section -->
    <Partners-Section />
    <!-- About section -->
		<About-Section />
    <!-- Register option section -->
		<Register-Option />
    <!-- testimonial section -->
    <Testimonial-Section />
    <!-- Become an agent section -->
    <Become-Agent />
    <!-- Subscriber section -->
		<Subscriber-Section />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AboutSection from '../components/Landing/About.vue';
import SubscriberSection from '../components/Landing/SubscriberSection.vue';
import RegisterOption from '../components/Landing/RegisterOption.vue';
import PartnersSection from '../components/Landing/Partners.vue';
import BecomeAgent from '../components/Landing/BecomeAgent.vue';
import TestimonialSection from '../components/Landing/TestimonialSection.vue';
import StaticSection from '../components/Landing/StaticSection.vue';
export default {
  components: { PartnersSection, AboutSection, SubscriberSection, RegisterOption, BecomeAgent, TestimonialSection, StaticSection },
  name: "HomeView",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {}
  },
};
</script>

<style scoped>

</style>