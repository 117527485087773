import store from "../../store";
import router from "../../router";
import WalletService from "../../services/WalletService";

export default {
  getWalletHistory(page) {
    store.dispatch("setLoading", true);
    const response = WalletService.getWalletHistory(page)
      .then((result) => {
        if (result.data.status) {
          const { data: { wallet_history } } = result.data;
          return { status: true, wallet_history };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  getWalletBalance() {
    store.dispatch("setLoading", true);
    const response = WalletService.getWalletBalance()
      .then((result) => {
        if (result.data.status) {
          const { data: { wallet_balance } } = result.data;
          store.dispatch("updateUserWallet", wallet_balance);
          return { status: true, wallet_balance };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  fundUserWallet(payload) {
    store.dispatch("setLoading", true);
    const response = WalletService.fundUserWallet(payload)
      .then((result) => {
        if (result.data.status) {
          const { data: { wallet_balance } } = result.data;
          store.dispatch("updateUserWallet", wallet_balance);
          return { status: true, wallet_balance };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  generatePaymentCode(payload) {
    store.dispatch("setLoading", true);
    const response = WalletService.generatePaymentCode(payload)
      .then((result) => {
        if (result.data.status) {
          return { status: true, data: result.data.data };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          return { status: false, message: data?.message };
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
};
