<template>
  <div>
    <Header></Header>
    <Fixed-Header/>
    <div class="wrapper">
      <Progress-Status />
      <router-view></router-view>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from './_partials/Header.vue'
import FixedHeader from './_partials/FixedHeader.vue'
import ProgressStatus from '@/components/ProgressStatus.vue';
import Footer from './_partials/Footer.vue'
  export default {
    name: "MainLayout",
    components: {
      Header,
      FixedHeader,
      Footer,
      ProgressStatus,
    }
  }
</script>