<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container position-relative">
          <i class="fa fa-times" aria-hidden="true" @click="$emit('close')"></i>
          <div class="modal-header">
            <h3 class="title-header mb_5 mt-1">Forgot Password</h3>
          </div>
          <div class="modal-body" v-if="!complete">
            <form
              action="#"
              method="post"
              class="mt-2"
              @submit.prevent="handleForgotPassword()"
            >
              <div class="form-group mb-1">
                <input
                  name="phone_number"
                  class="form__input bordered"
                  type="text"
                  v-model="fields.phone"
                  placeholder="Phone Number"
                  @keypress="$globalFunc.onlyNumber"
                  maxlength="11"
                  autofocus=""
                />
              </div>
              <div class="form-group">
                <button class="btn btn-standard mt-1 w-100" type="submit" :disabled="loading">
                  {{ loading ? "Please wait" : "Reset Account" }}
                </button>
              </div>
            </form>
            <p class="small mt-2 text-center">
              Already have an account?
              <a
                href="#"
                @click.prevent="$store.dispatch('handleModal', 'login')"
                >Login</a
              >
            </p>
          </div>
          <div class="modal-body" v-else>
            <Forgot-Password-Verification :phone="fields.phone" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AuthHelpers from "../../functions/actions/AuthRequest";
import ForgotPasswordVerification from "./ForgotPasswordVerification.vue";
export default {
  name: "ForgotPasswordModal",
  components: { ForgotPasswordVerification },
  computed: {
    ...mapState(["loading", "isUserLoggedIn"]),
    ...mapGetters(["isMerchant"])
  },
  beforeMount() {
    if (this.isUserLoggedIn) {
      this.$store.dispatch("handleModal", "forgotPassword");
    }
  },
  data() {
    return {
      fields: {
        phone: ""
      },
      complete: false,
    };
  },
  methods: {
    async handleForgotPassword() {
      if (this.validateRequest()) {
        const response = await AuthHelpers.forgotPassword(this.fields);
        if (response.status) {
          this.complete = true;
          this.showAlert("Success", "A token has sent to your phone number", "success");
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    validateRequest() {
      if (this.fields.phone) {
        return true;
      } else {
        this.showAlert(
          "Validation Error",
          "Kindly provide your phone number",
          "warn"
        );
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
</style>