<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="@/assets/buyunits/icons/dashboard/transaction-history-active.svg"
              class="img-fluid d-block mr-1"
            />
            <h2 class="dashboard-title">Transactions</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-start mt-1">
        <div class="col-lg-12">
          <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center
              height-50
            "
            v-if="transactions.length === 0"
          >
            <img
              src="@/assets/buyunits/icons/file-icon.svg"
              class="img-fluid d-block"
            />
            <p class="title text-center" style="color: #000">
              No Transaction History
            </p>
          </div>
          <div class="wallet-transactions table-responsive" v-else>
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ID</th>
                    <th scope="col">Meter</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Phone number</th>
                    <th scope="col">Token</th>
                    <th scope="col">Units</th>
                    <th scope="col">Amount</th>
                    <th scope="col" v-if="isMerchantActive">Commission</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in transactions"
                    :key="transaction.r"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ transaction.transaction_id }}</td>
                    <td>{{ transaction.meter_number }}</td>
                    <td>{{ transaction.customer_name }}</td>
                    <td>{{ transaction.phone }}</td>
                    <td v-if="transaction.token">
                      <img
                        src="@/assets/buyunits/icons/bi_copy_icon.svg"
                        alt="Copy icon"
                        class="img-fluid"
                        @click.prevent="copyToken(transaction.token)"
                      />
                      {{
                        transaction.token &&
                        $globalFunc.handleTokenSpacing(transaction.token, 4)
                      }}
                    </td>
                    <td v-else>No token generated yet</td>
                    <td>
                      {{
                        transaction.units &&
                        parseFloat(transaction.units).toFixed(2)
                      }}
                    </td>
                    <td>
                      &#8358;{{
                        transaction.amount &&
                        $globalFunc.currency(transaction.amount)
                      }}
                    </td>
                    <td v-if="isMerchantActive">
                      &#8358;{{
                        transaction.commission &&
                        $globalFunc.currency(transaction.commission)
                      }}
                    </td>
                    <td v-if="transaction.status == 'success'">
                      <span class="badge badge-pill badge-success"
                        >Success</span
                      >
                    </td>
                    <td v-else-if="transaction.status == 'pending'">
                      <span class="badge badge-pill badge-warning"
                        >Pending</span
                      >
                    </td>
                    <td v-else>
                      <span class="badge badge-pill badge-danger">Failed</span>
                    </td>
                    <td>{{ transaction.transaction_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TransactionHelpers from "@/functions/actions/TransactionRequest";
export default {
  name: "TransactionIndex",
  components: {},
  computed: {
    ...mapState(["loading"]),
    ...mapGetters(["isMerchantActive"]),
  },
  mounted() {
    this.getTransactions();
  },
  data() {
    return {
      transactions: [],
      page: 1,
    };
  },
  methods: {
    async getTransactions() {
      const response = await TransactionHelpers.getHistory(this.page);
      if (response.status) {
        this.transactions = response.transactions;
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    copyToken(token) {
      const tokenValue = this.$globalFunc.handleTokenSpacing(token, 4);
      this.$copyText(tokenValue, undefined, () => {});
      this.$notify({
        title: "Congratulations!",
        text: "Token copied to clipboard",
        type: "success",
      });
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>

<style scoped>
.wallet-top {
  font-family: "Maven Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #1d7947;
}
.wallet-amount {
  font-family: "Maven Pro", sans-serif;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #333333;
}
</style>