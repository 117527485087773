<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div
        class="row justify-content-center align-items-start mt-1"
       
      >
        <div class="col-12 col-md-6 col-lg-5">
          <div class="form-information request-bill mt-1">
            <img
              src="@/assets/buyunits/icons/dashboard/bi_calculator-icon.svg"
              alt="unit calcultor icon"
              class="img-fluid d-block mx-auto"
            />
            <h2 class="mb_5 mt-1">
              Request a Utility Bill
            </h2>
            <p class="small text-center">
              <strong>Need a Utility Bill?</strong> Fill the form below for utility bill
            </p>
            <form action="#" class="mt-2" @submit.prevent="handleSubmit()">
              <div class="form-group">
                <input
                  name="full_name"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.full_name"
                  placeholder="Full Name"
                  autofocus=""
                />
              </div>
              <div class="form-group">
                <select
                  name="country"
                  class="form-control mb-1"
                  v-model="fields.disco_id"
                >
                  <option value="" disabled selected>Select Disco</option>
                  <option
                    :value="disco.unique_id"
                    v-for="disco in discos"
                    :key="disco.unique_id"
                  >
                    {{ disco.title }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <input
                  name="meter_number"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.meter_number"
                  placeholder="Meter Number"
                  @keypress="$globalFunc.onlyNumber"
                  autofocus=""
                />
              </div>
              <div class="form-group">
                <input
                  name="email" 
                  class="form__input bordered mb-1" 
                  type="email" 
                  v-model="fields.email"
                  placeholder="Email Address"
                />
              </div>
              <div class="form-group">
                <button class="btn btn-standard mt-1" :disabled="loading" v-if="available">
                  {{ loading ? "Please wait" : "Submit" }}
                </button>
                <button class="btn btn-standard mt-1" :disabled="!available" v-else>
                  Coming Soon
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import ElectricityHelpers from "@/functions/actions/ElectricityRequest";
import RequestUtilityBillHelpers from "@/functions/actions/RequestUtilityBillRequest";
export default {
  name: "RequestUtilityBillIndex",
  components: {},
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.getDiscos();
  },
  data() {
    return {
      available: false,
      discos: [],
      fields: {
        full_name: "",
        meter_number: "",
        amount: "",
        disco_id: "",
      },
    };
  },
  methods: {
    async getDiscos() {
      const response = await ElectricityHelpers.retrieveDiscos()
      if (response.status) {
        this.discos = response.discos
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    async handleSubmit() {
      if (this.validateRequest()) {
        return false;
        const response = await RequestUtilityBillHelpers.calculateUnits(this.fields);
        if (response.status) {
          // generate the bill in a pdf
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    validateRequest() {
      if (this.fields.disco_id) {
        if (this.fields.meter_number) {
          if (this.fields.email) {
            return true;
          } else {
            this.showAlert(
              "Validation Error",
              "Kindly enter an email address",
              "error"
            );
          }
        } else {
          this.showAlert("Validation Error", "Kindly enter a meter number", "error");
        }
      } else {
        this.showAlert(
          "Validation Error",
          "Kindly select a disco",
          "error"
        );
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>

<style scoped>
</style>