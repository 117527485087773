<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="@/assets/buyunits/icons/dashboard/account-settings-active.svg"
              class="img-fluid d-block mr-1"
            />
            <div class="left">
              <h2 class="dashboard-title">API Keys & Instructions</h2>
              <p class="small" style="font-weight: 400;font-size: 12px;line-height: 22px;">Access your account using your API Key</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-start mt-1">
        <div class="col-12">
          <p class="m-0">Feel free to access our postman collection for all available endpoints. <a href="https://documenter.getpostman.com/view/25805034/2s935uFztu" target="_blank">Postman Collection</a></p>
          <p class="m-0">For testing purpose, ensure you use <strong>ONLY</strong> the test merchant credentials.</p>
          <p class="m-0"><strong>LIVE ENDPOINT:</strong> <a href="https://api.buyunits.ng" target="_blank">https://api.buyunits.ng</a></p>
          <p class="m-0"><strong>TEST ENDPOINT:</strong> <a href="https://sandbox-api.buyunits.ng" target="_blank">https://sandbox-api.buyunits.ng</a></p>
        </div>
      </div>
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="dashboard-card">
            <p class="title mb-1">API Configuration - Test Mode</p>
            <div class="alert alert-warning">
              These keys are for testing only. Please DO NOT use them in production.
            </div>
            <form action="#">
              <div class="form-group">
                <label class="label" for="test_private_key">
                  Test Private Key
                  <img
                    src="@/assets/buyunits/icons/bi_copy_icon.svg"
                    alt="Copy icon"
                    class="img-fluid copy-icon"
                    @click.prevent="copyToken(payload.merchant_test_private_key)"
                  />
                </label>
                <div class="password-input">
                  <input
                    :type="[revealTestPrivateKey ? 'text' : 'password']"
                    v-model="payload.merchant_test_private_key"
                    class="form__input bordered mb-1"
                    readonly
                    placeholder="• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •"
                  />
                  <i
                    :class="[revealTestPrivateKey ? 'fas fa-eye-slash' : 'fas fa-eye']"
                    @click.prevent="revealKeys('test')"
                  ></i>
                </div>
              </div>
              <div class="form-group">
                <label class="label" for="test_private_key">
                  Test Public Key
                  <img
                    src="@/assets/buyunits/icons/bi_copy_icon.svg"
                    alt="Copy icon"
                    class="img-fluid copy-icon"
                    @click.prevent="copyToken(payload.merchant_test_public_key)"
                  />
                </label>
                <div class="">
                  <input
                    type="text"
                    v-model="payload.merchant_test_public_key"
                    class="form__input bordered mb-1"
                    readonly
                    placeholder="• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-6">
          <div class="dashboard-card">
            <p class="title mb-1">API Configuration - Live Mode</p>
            <form action="#">
              <div class="form-group">
                <label class="label" for="live_private_key">
                  Live Private Key
                  <img
                    src="@/assets/buyunits/icons/bi_copy_icon.svg"
                    alt="Copy icon"
                    class="img-fluid copy-icon"
                    @click.prevent="copyToken(payload.merchant_live_private_key)"
                  />
                </label>
                <div class="password-input">
                  <input
                    :type="[revealLivePrivateKey ? 'text' : 'password']"
                    v-model="payload.merchant_live_private_key"
                    class="form__input bordered mb-1"
                    readonly
                    placeholder="• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •"
                  />
                  <i
                    :class="[revealLivePrivateKey ? 'fas fa-eye-slash' : 'fas fa-eye']"
                    @click.prevent="revealKeys('live')"
                  ></i>
                </div>
              </div>
              <div class="form-group">
                <label class="label" for="live_private_key">
                  Live Public Key
                  <img
                    src="@/assets/buyunits/icons/bi_copy_icon.svg"
                    alt="Copy icon"
                    class="img-fluid copy-icon"
                    @click.prevent="copyToken(payload.merchant_live_public_key)"
                  />
                </label>
                <div class="">
                  <input
                    type="text"
                    v-model="payload.merchant_live_public_key"
                    class="form__input bordered mb-1"
                    readonly
                    placeholder="• • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • • •"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PaymentMethod from "@/components/Payment/Index.vue";
import AccountSettingsHelpers from "@/functions/actions/AccountSettingsRequest";
export default {
  name: "AccountSettingsIndex",
  components: { PaymentMethod },
  computed: {
    ...mapState(["loading", "phone_number", "isUserLoggedIn"]),
    ...mapGetters(["isUser"]),
    passwordMatch() {
      return this.payload.confirm_new_password === this.payload.new_password
    }
  },
  mounted() {
    this.getIntegrationKey()
  },
  data() {
    return {
      revealTestPrivateKey: false,
      revealLivePrivateKey: false,
      payload: {
        merchant_test_public_key: "",
        merchant_test_private_key: "",
        merchant_live_public_key: "",
        merchant_live_private_key: "",
      }
    };
  },
  methods:{
    revealKeys(mode) {
      if (mode === 'live') {
        this.revealLivePrivateKey = !this.revealLivePrivateKey;
      } else {
        this.revealTestPrivateKey = !this.revealTestPrivateKey
      }
    },
    async getIntegrationKey() {
      const response = await AccountSettingsHelpers.getIntegrationKey()
      if (response.status) {
        this.payload = response.data;
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    copyToken(text2Copy) {
      this.$copyText(text2Copy, undefined, () => {});
      this.$notify({
        title: "Viola!",
        text: "Api Key copied to clipboard",
        type: "success",
      });
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
.dashboard-card .title {
  font-family: Poppins;
  color: #333333;
  text-align: center;
}
.password-input i, .select-input i {
  transform: translate(0%, -90%);
}
.label {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
}
.copy-icon {
  width: 20px;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 12px;
}
.alert.alert-warning {
  color: #c69e17;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.form__input {
  font-size: 14px;
}
.form__input.bordered {
  height: 35px;
  padding: 0 55px 0px 15px;
}
.about p {
  font-size: 14px;
}
</style>