import store from "../../store";
import router from "../../router";
import AccountSettingsService from "../../services/AccountSettingsService";

export default {
  changePassword(page) {
    store.dispatch("setLoading", true);
    const response = AccountSettingsService.changePassword(page)
      .then((result) => {
        if (result.data.status) {
          const { status } = result.data;
          return { status };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message };
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  updateProfile(payload) {
    store.dispatch("setLoading", true);
    const response = AccountSettingsService.updateProfile(payload)
      .then((result) => {
        if (result.data.status) {
          const { status, message } = result.data;
          store.dispatch("updateUserProfile", payload);
          return { status, message };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message };
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  resendPhoneToken() {
    store.dispatch("setLoading", true);
    const response = AccountSettingsService.resendPhoneToken()
      .then((result) => {
        if (result.data.status) {
          const { status, message } = result.data;
          return { status, message };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message };
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  verifyPhoneToken(payload) {
    store.dispatch("setLoading", true);
    const response = AccountSettingsService.verifyPhoneToken(payload)
      .then((result) => {
        if (result.data.status) {
          const { status, message } = result.data;
          return { status, message };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message };
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  getIntegrationKey() {
    store.dispatch("setLoading", true);
    const response = AccountSettingsService.getIntegrationKey()
      .then((result) => {
        if (result.data.status) {
          const { status, message, data } = result.data;
          return { status, message, data };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message };
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
};
