<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="@/assets/buyunits/icons/dashboard/meter-numbers-active.svg"
              class="img-fluid d-block mr-1"
            />
            <h2 class="dashboard-title">Saved Meter Numbers</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-start mt-1" v-if="meters.length === 0">
        <div class="col-lg-12">
          <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center
              height-50
            "
          >
            <img
              src="@/assets/buyunits/icons/file-icon.svg"
              class="img-fluid d-block"
            />
            <p class="title text-center" style="color: #000">
              No Saved Meter Numbers
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-start align-items-start mt-1" v-else>
        <!-- <div class="col-md-4 col-lg-3">
          <div class="saved-meters-template mb-2" @click="$notify('Warning', 'This feature is temporarily unavailable', 'warn')">
          </div>
        </div> -->
        <div class="col-6 col-md-4 col-lg-4" v-for="(meter, index) in meters" :key="index">
          <div class="saved-meter mb-2">
            <div class="meter-name">{{ meter.customer_name }}</div>
            <div class="meter-number">{{ meter.meter_number }}</div>
            <div class="d-flex justify-content-between align-items-center mt-2">
              <button class="btn-link" @click.prevent="purchaseToken(meter.disco_id, meter.meter_number)">BUY NOW</button>
              <button class="btn-link trash" @click.prevent="deleteMeterNumbers(meter.unique_id)">
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import MeterNumbersHelpers from "@/functions/actions/MeterNumbersRequest";
export default {
  name: "MeterNumbersIndex",
  components: {},
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.getMeterNumbers();
  },
  data() {
    return {
      meters: [],
    };
  },
  methods:{
    async getMeterNumbers() {
      const response = await MeterNumbersHelpers.getMeterNumbers()
      if (response.status) {
        this.meters = response.meters
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    purchaseToken(discoId, meterNumber) {
      this.$router.push({ 
        name: 'SellElectricity', 
        query: { 
          disco_id: discoId,
          meter_number: meterNumber
        }
      })
    },
    async deleteMeterNumbers(meterId) {
      if (confirm('Are you sure you want to delete this meter?')) {
        const response = await MeterNumbersHelpers.deleteMeterNumbers({ meter_id: meterId })
        if (response.status) {
          this.meters = this.meters.filter(meter => meter.unique_id !== meterId)
          this.showAlert('Success', "Meter number deleted successfully", 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
</style>