import store from "../../store";
import router from "../../router";
import ElectricityService from "../../services/ElectricityService";

export default {
  retrieveDiscos() {
    store.dispatch("setLoading", true);
    const response = ElectricityService.retrieveDiscos()
      .then((result) => {
        const { data } = result.data;
        return { status: true, discos: data };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  lastTransaction(phone) {
    store.dispatch("setLoading", true);
    const response = ElectricityService.lastTransaction(phone)
      .then((result) => {
        const { status, data } = result.data;
        if (status) {
          return { status: true, transaction: data.transaction };
        }
        return { status };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  verifyElectricity(payload) {
    store.dispatch("setLoading", true);
    const response = ElectricityService.verifyElectricity(payload)
      .then((result) => {
        store.dispatch("setTransactionDetails", {
          ...result.data.data,
          service: "electricity",
        });
        store.dispatch('updateCurrentStep', 2);
        if (store.state.isUserLoggedIn) {
          router.push({
            name: "SellElectricityDetails",
          });
        } else {
          router.push({
            name: "GuestPurchaseDetails",
          });
        }
        return { status: true };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  vendElectricity(payload) {
    store.dispatch("setLoading", true);
    const response = ElectricityService.vendElectricity(payload)
      .then((result) => {
        store.dispatch("setTransactionDetails", {
          ...store.getters.getDetails, ...result.data.data,
        });
        store.dispatch('updateCurrentStep', 3);
        if (store.state.isUserLoggedIn) {
          router.push({
            name: "SellElectricityReceipt",
          });
        } else {
          router.push({
            name: "GuestPurchaseReceipt",
          });
        }
        return { status: true };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
};
