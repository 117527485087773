<template>
  <div
    class="row justify-content-center align-items-center mt-1"
    v-if="type === 'bank_transfer'"
  >
    <div class="col-lg-12">
      <div class="top d-flex justify-content-center align-items-center">
        <img
          src="@/assets/buyunits/icons/bi_arrow-left-short.svg"
          alt="Guest Home"
          class="img-fluid cursor-pointer"
          @click.prevent="handleClose()"
        />
        <div class="upper-section ml-1">
          <h2 class="m-0 text-center">Pay with Bank Transfer</h2>
        </div>
      </div>
      <div class="middle">
        <p class="transfer">
          Transfer <span>N{{ amount && $globalFunc.currency(amount) }}</span> to
          the Account <br /> Number below
        </p>
        <img
          src="@/assets/buyunits/icons/eva_arrow-down-fill.svg"
          alt="down icon"
          class="img-fluid d-block mx-auto"
        />
        <p class="account">
          <a :href="`tel:${data.account_number}`">{{data && data.account_number}}</a>
        </p>
        <p class="small">{{ data && data.bank }}</p>
        <button
          class="btn btn-standard w-100 mt-1"
          :disabled="loading"
          @click.prevent="handleSubmit()"
        >
          {{ loading ? "Please wait" : `Confirm` }}
        </button>
      </div>
    </div>
  </div>
  <div
    class="row justify-content-center align-items-center mt-1"
    v-if="type === 'ussd'"
  >
    <div class="col-lg-12">
      <div class="top d-flex justify-content-center align-items-center">
        <img
          src="@/assets/buyunits/icons/bi_arrow-left-short.svg"
          alt="Guest Home"
          class="img-fluid cursor-pointer"
          @click.prevent="handleClose()"
        />
        <div class="upper-section ml-1">
          <h2 class="m-0 text-center">Pay with USSD</h2>
        </div>
      </div>
      <div class="middle">
        <p class="transfer" v-if="selectedCode">
          Transfer <span>N{{ amount && $globalFunc.currency(amount) }}</span> to
          the Account <br /> Number below
        </p>
        <div v-else>
          <p class="transfer" style="margin-bottom: 0">
            What bank are you paying from?
          </p>
          <p class="small">Select your bank to pay with USSD</p>
        </div>
        <div class="form-information">
          <form action="">
            <div class="form-group">
              <select name="country" class="form-control mb-1" v-model="selectedCode" style="width: 100%">
                <option value="" disabled selected>Select a Bank</option>
                <option :value="bank.id" v-for="bank in data.banks" :key="bank.id">{{ bank.bank }}</option>
              </select>
            </div>
          </form>
        </div>
        <div v-if="selectedCode">
          <p class="small">{{ ussdBank }}</p>
          <p class="account">
            <a :href="`tel:${ussdCode}`">
              {{ ussdCode }}
            </a>
          </p>
        </div>
        <button
          class="btn btn-standard w-100 mt-1"
          :disabled="loading || (!selectedCode && ussdBank === '')"
          @click.prevent="selectedCode && handleSubmit()"
        >
          {{ loading ? "Please wait" : selectedCode ? `Confirm` : `Get USSD Code` }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ElectricityHelpers from "@/functions/actions/ElectricityRequest";
export default {
  name: "AlternatePayment",
  props: {
    type: {
      type: String,
      required: true,
    },
    amount: {
      type: [Number, String],
      required: true,
    },
    transaction_id: {
      type: [Number, String],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    method: {
      type: String,
      default: "transaction",
    },
  },
  computed: {
    ...mapState(["loading"]),
    ussdCode() {
      return this.selectedCode && this.data.banks.find(
        (bank) => bank.id === this.selectedCode
      ).code;
    },
    ussdBank() {
      return this.selectedCode && this.data.banks.find(
        (bank) => bank.id === this.selectedCode
      ).bank;
    },

  },
  data() {
    return {
      selectedCode: "",
    };
  },
  methods: {
    async handleSubmit() {
      if (this.method === "wallet") {
        this.$emit("fundWallet");
        return false;
      } else {
        const response = await ElectricityHelpers.vendElectricity({
          payment_method: this.type, 
          transaction_id: this.transaction_id.toString()
        });
        if (response.status) {
          // payment was successful, route user to receipt page
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    handleClose() {
      if (confirm("Are you sure? This transaction cannot be undone?")) {
        this.$emit("close");
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
.middle {
  font-family: "Poppins";
  text-align: center;
  justify-content: center;
}
.middle p.transfer {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}
.middle p.transfer span {
  color: #28aa63;
}
.middle p.account {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 0;
}
</style>