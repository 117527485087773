<template>
  <section id="about" class="about section">
    <div class="container" id="printReceipt">
      <img src="@/assets/buyunits/icons/success-icon.svg" alt="Guest Home" class="img-fluid d-block mx-auto mt-1" />
      <div class="row justify-content-center align-items mt-1">
        <div class="upper-section">
          <h2 class="text-center">Successful</h2>
          <p class="smaller m-0 text-center">Thank you for trusting us with your bill payment</p>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6">
          <h4 class="text-center uppercase mt-1" v-if="isMerchantActive">{{ isMerchant.business_name }}</h4>
          <Order-Receipt />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import OrderReceipt from "@/components/Service/OrderReceipt";
export default {
  name: "DashboardSellReceipt",
  components: {
    OrderReceipt,
  },
  computed: {
    ...mapState(["loading", "phone_number"]),
    ...mapGetters(["getDetails", "isMerchantActive", "isMerchant"]),
  },
  beforeMount() {
    if (!this.getDetails) {
      this.$router.push({
        name: "SellElectricity"
      })
    }
    this.$store.dispatch('updateCurrentStep', 3);
    this.$store.dispatch('toggleSteps', 1);
  },
  mounted() {},
  beforeUnmount() {
    this.$store.dispatch('toggleSteps', 0);
    this.$store.dispatch("clearTransactionDetails");
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
.upper-section h2 {
  font-size: 26px;
  font-weight: 500;
}
</style>