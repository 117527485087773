import Api from "@/services/Api";

export default {
  register(credentials) {
    return Api().post("/auth/register", credentials);
  },
  login(credentials) {
    return Api().post("/auth/login", credentials);
  },
  forgotPassword(credentials) {
    return Api().post("/auth/forgot-password", credentials);
  },
  resetPassword(credentials) {
    return Api().post("/auth/validate-token", credentials);
  },
  logout() {
    return Api().get("/auth/logout");
  }
};