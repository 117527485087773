<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="@/assets/buyunits/icons/dashboard/saved-cards-active.svg"
              class="img-fluid d-block mr-1"
            />
            <h2 class="dashboard-title">Saved Cards</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-start mt-1" v-if="cards.length === 0">
        <div class="col-lg-12">
          <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center
              height-50
            "
          >
            <img
              src="@/assets/buyunits/icons/file-icon.svg"
              class="img-fluid d-block"
            />
            <p class="title text-center" style="color: #000">
              No Saved Cards
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-start align-items-start mt-1" v-else>
        <!-- <div class="col-md-4 col-lg-3">
          <div class="saved-card-template mb-2">
            
          </div>
        </div> -->
        <div class="col-md-4 col-lg-4" v-for="(card, index) in cards" :key="index">
          <div class="saved-card mb-2">
            <img
              src="@/assets/buyunits/icons/visa-icon.svg"
              class="img-fluid d-block card-type"
              v-if="card.card_type.trim() === 'visa'"
            />
            <img
              src="@/assets/buyunits/icons/mastercard-icon.svg"
              class="img-fluid d-block card-type"
              v-else
            />
            <img
              src="@/assets/buyunits/icons/card-design-top.svg"
              class="img-fluid d-block top-banner"
            />
            <img
              src="@/assets/buyunits/icons/card-design-bottom.svg"
              class="img-fluid d-block bottom-banner"
            />
            <div class="card-number mt-3">**** **** **** {{ card.card_number }}</div>
            <div class="d-flex justify-content-between align-items-center mt-1">
              <div class="card-name-block">
                <p class="m-0 card-name-title">NAME</p>
                <p class="m-0 card-name">{{ card.card_name }}</p>
              </div>
              <img src="@/assets/buyunits/icons/bi_icons_trash.svg" class="img-fluid trash-icon" @click.prevent="deleteSavedCards(card.unique_id)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import SavedCardsHelpers from "@/functions/actions/SavedCardsRequest";
export default {
  name: "SavedCardsIndex",
  components: {},
  computed: {
    ...mapState(["loading"]),
  },
  mounted() {
    this.getSavedCards();
  },
  data() {
    return {
      cards: [],
    };
  },
  methods:{
    async getSavedCards() {
      const response = await SavedCardsHelpers.getSavedCards()
      if (response.status) {
        this.cards = response.cards
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    async deleteSavedCards(cardId) {
      if (confirm('Are you sure you want to delete this card?')) {
        const response = await SavedCardsHelpers.deleteSavedCards({ card_id: cardId })
        if (response.status) {
          this.cards = this.cards.filter(card => card.unique_id !== cardId)
          this.showAlert('Success', "Card deleted successfully", 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
</style>