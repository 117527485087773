<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="@/assets/buyunits/icons/dashboard/wallet-active.svg"
              class="img-fluid d-block mr-1"
            />
            <h2 class="dashboard-title">Wallet</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-start mt-1">
        <div class="col-12 col-md-12 col-lg-4 col-xl-4">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-12">
              <Wallet-Card type="wallet" />
            </div>
            <div class="col-12">
              <div class="dashboard-card mb-1">
                <p class="title">Fund Wallet</p>
                <div class="form-group">
                  <input
                    name="amount"
                    class="form__input bordered mb-1"
                    type="text"
                    v-model="fields.amount"
                    placeholder="Enter Amount"
                    @keyup="validateAllowedValue"
                    @keypress="$globalFunc.onlyNumber"
                  />
                </div>
                <div class="row justify-content-center align-items-center">
                  <div class="col-lg-12">
                    <Wallet-Payment :amount="fields.amount" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-8 col-xl-8">
          <div class="dashboard-card">
            <p class="title mb-1">Transaction History</p>
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                height-80
              "
              v-if="transactions.length === 0"
            >
              <img
                src="@/assets/buyunits/icons/file-icon.svg"
                class="img-fluid d-block"
              />
              <p class="title text-center" style="color: #000">
                No Transaction History
              </p>
            </div>
            <div class="wallet-transactions table-responsive" v-else>
              <table class="table">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Transaction Reference</th>
                    <th scope="col">Description</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Current Balance</th>
                    <th scope="col">New Balance</th>
                    <th scope="col">Type</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, index) in transactions" :key="transaction.r">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ transaction.transaction_reference }}</td>
                    <td>{{ transaction.description }}</td>
                    <td>&#8358;{{ $globalFunc.currency(transaction.transaction_amount) }}</td>
                    <td>&#8358;{{ $globalFunc.currency(transaction.current_balance) }}</td>
                    <td>&#8358;{{ $globalFunc.currency(transaction.new_balance) }}</td>
                    <td v-if="transaction.type == 'credit'">
                      <span class="badge badge-pill badge-success">Credit</span>
                    </td>
                    <td v-else>
                      <span class="badge badge-pill badge-warning">Debit</span>
                    </td>
                    <td>{{ transaction.transaction_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import WalletPayment from "@/components/Payment/WalletPayment.vue";
import WalletCard from "@/components/Dashboard/WalletCard.vue";
import WalletHelpers from "@/functions/actions/WalletRequest";
export default {
  name: "WalletIndex",
  components: { WalletPayment, WalletCard },
  computed: {
    ...mapState(["loading", "phone_number", "isUserLoggedIn"]),
    ...mapGetters(["isMerchant", "isMerchantActive", "walletBalance"]),
  },
  mounted() {
    this.getTransactions();
    this.getWalletBalance();
  },
  data() {
    return {
      fields: {
        amount: "1000",
      },
      transactions: [],
      page: 1,
    };
  },
  methods:{
    async getTransactions() {
      const response = await WalletHelpers.getWalletHistory(this.page)
      if (response.status) {
        this.transactions = response.wallet_history
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    async getWalletBalance() {
      const response = await WalletHelpers.getWalletBalance()
      if (response.status) {
      } else {
        this.showAlert('Error occured', response.message, 'error')
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
</style>