import store from "../../store";
import router from "../../router";
import BecomeMerchantService from "../../services/BecomeMerchantService";

export default {
  upgradeAccount(payload) {
    store.dispatch("setLoading", true);
    const response = BecomeMerchantService.upgradeAccount(payload)
      .then((result) => {
        if (result.data.status) {
          const { data: { merchant } } = result.data;
          store.dispatch("updateMerchantInfo", merchant);
          return { status: true, merchant };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Home",
            });
            return { status: false, message: data?.message };
          } else {
            return { status: false, message: data?.message };
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
};
