<template>
    <section class="about section section--no-pt ">
        <div class="container">
            <div class="row about__first-row" style="align-items: center">
                <div class="col-lg-6">
                    <div class="block-header">
                        <h2 class="title title--black title--medium mb_5">Become An Agent</h2>
                        <p class="m-0 title--subtitle font-2">Resell and Earn with our amazing commission</p>
                        <hr class="tagline ml-0">
                    </div>
                    <p class="mb-1">
                        Register as a certified Buy Units agent and earn instant commissions whenever you sell electricity. Whether you're a small business or an individual looking to make some extra cash, all you need to get started is a smart phone and internet. Get started today!
                    </p>
                    <button class="btn btn-standard register-agent mb-1" @click.prevent="$store.dispatch('handleModal', 'register')">
                        Register as an Agent &nbsp;
                        <i class="fas fa-arrow-right"></i>
                    </button>
                </div>
                <div class="col-lg-6">
                    <img v-lazy="agentImage" class="img-fluid d-flex mx-auto" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "BecomeAgent",
    computed: {},
    data() {
        return {
            agentImage: require('@/assets/buyunits/background-bulb.svg')
        }
    },
}
</script>
<style scoped>   
.about__first-row {
  margin: 50px auto 40px;
}
@media (max-width: 960px) {
    .section {
        padding: 0;
    }
}
</style>