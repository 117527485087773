import Api from "@/services/Api";

export default {
  validUserPhone(phoneNumber) {
    return Api().get(`/retrieve/${phoneNumber}`);
  },
  generatePaymentCode(payload) {
    return Api().post(`/electricity/generate/code`, payload);
  },
};
