<template>
  <section id="about" class="about section">
    <div class="container">
      <div class="row" style="align-items: center">
        <div class="col-lg-12">
          <div class="block-header">
            <h2 class="title title--black title--medium">About us</h2>
          </div>
          <p>
            Buy Units is an online platform that provides users the convenience of paying for prepaid and postpaid electricity from the comfort of their homes or offices. When you buy power units on our platform, be rest assured that you will get value. Our customer service team is equipped with the necessary tools to provide you with prompt support when needed.
          </p>
          <p>
            We are currently providing our customers with access to all the 12 Electricity Distribution Companies In Nigeria.
          </p>
        </div>
      </div>
      <div class="row justify-content-start align-items mt-2">
        <router-link to="/">
          <img src="@/assets/buyunits/icons/guest-home.svg" alt="Guest Home" class="img-fluid" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AboutPage",
  computed: {
    ...mapState(["loading"]),
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.about p {
  margin-bottom: 10px;
}
.about.section {
  margin-top: 70px;
}
.about .block-header {
  margin-bottom: 10px;
}
.title--medium {
  font-size: 25px;
}
</style>