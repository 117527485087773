<template>
  <section id="about" class="about section main-view">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center mb-1">
        <div class="col-lg-12">
          <div class="d-flex justify-content-start align-items-center">
            <img
              src="@/assets/buyunits/icons/dashboard/account-settings-active.svg"
              class="img-fluid d-block mr-1"
            />
            <div class="left">
              <h2 class="dashboard-title">Account Settings</h2>
              <p class="small" style="font-weight: 400;font-size: 12px;line-height: 22px;">Update Your Profile Information</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start align-items-start mt-1">
        <div class="col-12 col-md-6 col-lg-5 col-xl-5">
          <div class="dashboard-card mb-1">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <p class="title m-0">Personal Info</p>
              <div class="icon d-flex justify-content-start align-items-center" :class="[{'red': edit}]" @click.prevent="toggleEdit()">
                {{ !edit ? "Edit" : "Cancel" }}
                <img
                  src="@/assets/buyunits/icons/edit-icon.svg"
                  class="img-fluid d-block ml_5"
                  v-if="!edit"
                />
                <i class="fa fa-times ml_5" aria-hidden="true" v-else></i>
              </div>
            </div>
            <form action="#" @submit.prevent="updateProfile()">
              <div class="form-group">
                <input
                  name="name"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.name"
                  placeholder="Fullname"
                  :disabled="!edit"
                />
              </div>
              <div class="form-group">
                <input
                  name="phone"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.phone"
                  @keypress="$globalFunc.onlyNumber"
                  placeholder="Phone number"
                  maxlength="11"
                  :disabled="!edit"
                />
              </div>
              <div class="form-group">
                <input
                  name="email"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.email"
                  placeholder="Email Address"
                  :disabled="!edit"
                />
              </div>
              <div class="form-group">
                <button class="btn btn-standard w-100 mt-1" type="submit" :disabled="loading || !edit">{{ loading ? "Please wait" : "Save" }}</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 col-xl-5">
          <div class="dashboard-card">
            <p class="title mb-1">Change Password</p>
            <form action="#" @submit.prevent="changePassword()">
              <div class="form-group">
                <div class="password-input">
                  <input
                    :type="[reveal ? 'text' : 'password']"
                    v-model="payload.current_password"
                    class="form__input bordered mb-1"
                    placeholder="**************************"
                  />
                  <i
                    :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']"
                    @click.prevent="revealPassword"
                  ></i>
                </div>
              </div>
              <div class="form-group">
                <div class="password-input">
                  <input
                    :type="[reveal ? 'text' : 'password']"
                    v-model="payload.new_password"
                    class="form__input bordered mb-1"
                    placeholder="**************************"
                  />
                  <i
                    :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']"
                    @click.prevent="revealPassword"
                  ></i>
                </div>
              </div>
              <div class="form-group">
                <div class="password-input">
                  <input
                    :type="[reveal ? 'text' : 'password']"
                    v-model="payload.confirm_new_password"
                    class="form__input bordered mb-1"
                    placeholder="**************************"
                  />
                  <i
                    :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']"
                    @click.prevent="revealPassword"
                  ></i>
                  <span class="error" v-if="payload.confirm_new_password.length > 0  && !passwordMatch">Confirm password doesn't match</span>
                </div>
              </div>
              <div class="form-group">
                <p class="small m-0" style="font-size: 12px;line-height: 16px;color: #929292;">Password must contains at least one letter, one digit and one special character (!$#@)</p>
              </div>
              <div class="form-group">
                <button class="btn btn-standard w-100 mt-1" type="submit" :disabled="loading">{{ loading ? "Please wait" : "Save" }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PaymentMethod from "@/components/Payment/Index.vue";
import AccountSettingsHelpers from "@/functions/actions/AccountSettingsRequest";
export default {
  name: "AccountSettingsIndex",
  components: { PaymentMethod },
  computed: {
    ...mapState(["loading", "phone_number", "isUserLoggedIn"]),
    ...mapGetters(["isUser"]),
    passwordMatch() {
      return this.payload.confirm_new_password === this.payload.new_password
    }
  },
  mounted() {
    this.fields = {
      name: this.isUser.name,
      phone: this.isUser.phone,
      email: this.isUser.email,
    };
  },
  data() {
    return {
      reveal: false,
      edit: false,
      fields: {
        name: "",
        phone: "",
        email: "",
      },
      payload: {
        current_password: "",
        new_password: "",
        confirm_new_password: "",
      }
    };
  },
  methods:{
    revealPassword() {
      this.reveal = !this.reveal;
    },
    toggleEdit() {
      this.edit = !this.edit;
      if (!this.edit) {
        this.fields = {
          name: this.isUser.name,
          phone: this.isUser.phone,
          email: this.isUser.email,
        };
      }
    },
    async updateProfile() {
      if (this.validateProfileRequest()) {
        const response = await AccountSettingsHelpers.updateProfile(this.fields)
        if (response.status) {
          // update profile success 
          this.edit = false;
          this.showAlert('Success', "Profile info updated successfully", 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      }
    },
    async changePassword() {
      if (this.validatePasswordRequest()) {
        const response = await AccountSettingsHelpers.changePassword(this.payload)
        if (response.status) {
          // update password successful
          this.$store.dispatch('logout')
          this.$router.push({
            name: "Home",
          });
          this.showAlert('Success', "Password updated successfully. You have been logged out", 'success')
        } else {
          this.showAlert('Error occured', response.message, 'error')
        }
      }
    },
    validateProfileRequest() {
      if (this.fields.name) {
        if (this.fields.phone) {
          if (this.fields.phone.length === 11) {
            if (this.fields.email) {
              return true;
            } else {
              this.showAlert('Validation Error', 'Please enter an email address', 'error')
            }
          } else {
            this.showAlert('Validation Error', 'Phone number must be 11 digits', 'error')
          }
        } else {
          this.showAlert('Validation Error', 'Enter your phone number', 'error')
        }
      } else {
        this.showAlert('Validation Error', 'Please enter your name', 'error')
      }
    },
    validatePasswordRequest() {
      if (this.payload.current_password) {
        if (this.payload.new_password) {
          if (this.payload.confirm_new_password === this.payload.new_password) {
            return true;
          } else {
            this.showAlert('Validation Error', 'Confirm password mismatch', 'error')
          }
        } else {
          this.showAlert('Validation Error', 'Please enter your new password', 'error')
        }
      } else {
        this.showAlert('Validation Error', 'Please enter your current password', 'error')
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
.dashboard-card .title {
  font-family: Poppins;
  color: #333333;
}
.password-input i, .select-input i {
  transform: translate(0%, -90%);
}
.dashboard-card .icon {
  font-family: Poppins;
  font-size: 14px;
  color: #28AA63;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}
.dashboard-card .icon.red {
  color: red;
}
</style>