import store from "../../store";
import GeneralService from "../../services/GeneralService";

export default {
  validUserPhone(phoneNumber) {
    store.dispatch("setLoading", true);
    const response = GeneralService.validUserPhone(phoneNumber)
      .then((result) => {
        if (result.data.status) {
          return { status: true };
        }
        return { status: false };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          return { status: false, message: data?.message };
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
  generatePaymentCode(payload) {
    store.dispatch("setLoading", true);
    const response = GeneralService.generatePaymentCode(payload)
      .then((result) => {
        if (result.data.status) {
          return { status: true, data: result.data.data };
        }
        return { status: false, message: result.data.message };
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return {
            status: false,
            message: "Oops! took long to get a response",
          };
        } else {
          const { status, data } = err.response;
          return { status: false, message: data?.message };
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
    return response;
  },
};
