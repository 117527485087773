<template>
  <form action="" class="text-center" @submit.prevent="validateToken()">
    <div class="row">
      <div class="col-lg-12 mt-1">
        <p class="small mb_5">Enter the 6 digit token sent to your phone.</p>
      </div>
      <div class="col-lg-12">
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(1)"
          maxlength="1"
          v-model="pin.one"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(2)"
          maxlength="1"
          v-model="pin.two"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(3)"
          maxlength="1"
          v-model="pin.three"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(4)"
          maxlength="1"
          v-model="pin.four"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(5)"
          maxlength="1"
          v-model="pin.five"
        />
        <input
          class="otp"
          type="text"
          oninput="digitValidate(this)"
          onkeyup="tabChange(6)"
          maxlength="1"
          v-model="pin.six"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <!-- Password input -->
        <div class="password-input mt-1">
          <input
            :type="[reveal ? 'text' : 'password']"
            v-model="password"
            class="form__input bordered mb_5"
            placeholder="Password"
          />
          <i
            :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']"
            @click.prevent="revealPassword"
          ></i>
        </div>
        <div class="form-group">
          <p class="small m-0" style="font-size: 12px;line-height: 16px;color: #929292;">Password must contains at least one letter, one digit and one special character (!$#@)</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-1 mb-1">
        <button
          class="btn btn-standard w-75"
          type="submit"
          :disabled="loading || combinedOtp.length !== 6 || !password"
        >
          {{ !loading ? "Complete Reset" : "Please wait..." }}
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapState } from "vuex";
import AuthHelpers from "@/functions/actions/AuthRequest";
export default {
  name: "ForgotPasswordVerification",
  computed: {
    ...mapState(["loading"]),
    combinedOtp() {
      return `${this.pin.one}${this.pin.two}${this.pin.three}${this.pin.four}${this.pin.five}${this.pin.six}`;
    },
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pin: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
      password: "",
      reveal: false,
    };
  },
  methods: {
    revealPassword() {
      this.reveal = !this.reveal;
    },
    async validateToken() {
      const payload = {
        phone: this.phone,
        token: this.combinedOtp,
        password: this.password,
      };
      const response = await AuthHelpers.resetPassword(payload);
      if (response.status) {
        // successful
        this.showAlert("Success", response.message, "success");
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
form input {
  display: inline-block;
  width: 40px;
  height: 45px;
  text-align: center;
  margin: auto 10px;
}
</style>