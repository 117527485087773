<template>
    <div class="row partners-section">
        <div class="col">
            <div class="partners-carousel owl-carousel">
                <div class="partners-carousel__item" v-for="partner in partners" :key="partner.id">
                    <div class="partners__logo">
                        <img v-lazy="partner.image" :alt="partner.alt" class="img-fluid d-flex mx-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Partners",
    computed: {},
    data() {
        return {
            partners: [
                {
                    id: 1,
                    image: '/assets/img/partners/AEDC.svg',
                    alt: 'AEDC Logo'
                },
                {
                    id: 2,
                    image: '/assets/img/partners/EKEDC.svg',
                    alt: 'EKEDC Logo'
                },
                {
                    id: 3,
                    image: '/assets/img/partners/KEDCO.svg',
                    alt: 'KEDCO Logo'
                },
                {
                    id: 4,
                    image: '/assets/img/partners/EEDC.svg',
                    alt: 'EEDC Logo'
                },
                {
                    id: 5,
                    image: '/assets/img/partners/IKEJA.svg',
                    alt: 'IKEJA Logo'
                },
                {
                    id: 6,
                    image: '/assets/img/partners/JEDC.svg',
                    alt: 'JEDC Logo'
                },
                {
                    id: 7,
                    image: '/assets/img/partners/IBEDC.svg',
                    alt: 'IBEDC Logo'
                },
            ]
        }
    },
}
</script>
<style scoped>
</style>