<template>
  <div class="row justify-content-center align-items-center g-0 height-100">
    <div class="col-sm-10 col-lg-6">
      <div class="content auth d-flex flex-column justify-content-center h-100">
        <div class="auth-form">
          <img src="@/assets/buyunits/404.svg" class="img-fluid mx-auto d-block" alt="404 image">
          <h4 class="text-center mb-2 mt-5">404! Page Not Found</h4>
          <p class="text-center mb-2">
            Oops! you seem lost, no worries we will handle the redirection to
            the entry point, If this doesn't happen, kindly click the button
            below.
          </p>
          <div class="text-center">
            <router-link to="/">
              <button type="submit" class="btn btn-standard w-75 fw-600">
                <i class="fa fa-arrow-left"></i>
                Back to Home
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "404Page",
};
</script>
<style scoped>
.auth-form h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #28aa63;
}
.auth-form p {
  line-height: 30px;
}
</style>