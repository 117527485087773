<template>
  <section id="about" class="about section newsletter section--no-pt">
    <div class="container">
      <div class="row about__first-row" style="align-items: center">
        <div class="col-lg-12">
          <div class="block-header text-center">
            <h2 class="title title--black title--medium mb_5">
              Subscribe to Our Newsletter
            </h2>
            <p class="m-0 title--subtitle font-2">
              Subscribe to our newsletter
            </p>
            <hr class="tagline" />
          </div>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-8">
          <form class="newsletter-form" @submit.prevent="">
            <input type="email" placeholder="Enter your email..." required />
            <button type="submit">
              Confirm &nbsp;
              <i class="fas fa-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-4">
          <ul class="d-flex justify-content-center align-items-center mt-3 mb_5 social-content">
            <li>
              <a href="#" aria-label="Facebook logo" @click.prevent="handleSocialLinks('facebook')">
                <img v-lazy="facebookImage" alt="Facebok icon" class="img-fluid d-flex-mx-auto">
              </a>
            </li>
            <li>
              <a href="#" aria-label="Twitter logo" @click.prevent="handleSocialLinks('twitter')">
                <img v-lazy="twitterImage" alt="Twitter icon" class="img-fluid d-flex-mx-auto">
              </a>
            </li>
            <li>
              <a href="#" aria-label="Instagram logo" @click.prevent="handleSocialLinks('instagram')">
                <img v-lazy="instagramImage" alt="Instagram icon" class="img-fluid d-flex-mx-auto">
              </a>
            </li>
          </ul>
          <p class="text-center">Follow @BuyUnits.ng on all social media</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "SubscriberSection",
  components: {},
  data() {
    return {
      facebookImage: require("@/assets/buyunits/icons/facebook-colored.svg"),
      twitterImage: require("@/assets/buyunits/icons/twitter-colored.svg"),
      instagramImage: require("@/assets/buyunits/icons/instagram-colored.svg"),
      linkedinImage: require("@/assets/buyunits/icons/linkedin-colored.svg"),
    };
  },
  methods: {
    handleSocialLinks(social) {
      switch (social) {
        case "facebook":
          window.open("https://www.facebook.com/BuyUnitsNG/", "_blank");
          break;
        case "twitter":
          window.open("https://twitter.com/buyunitsng", "_blank");
          break;
        case "instagram":
          window.open("https://www.instagram.com/buyunitsng/", "_blank");
          break;
      }
    },
  },
};
</script>
<style scoped>
.about__first-row {
  margin: 70px auto 40px;
}
@media (max-width: 960px) {
  .section {
    padding: 0;
  }
}
.card-style {
  height: 50vh;
}
.card-style:hover {
  border: 2px solid #28aa63;
}
.card-style:hover .about__item-content h3 {
  color: #28aa63;
}
.about__item-content h3 {
  font-size: 25px;
  line-height: 2rem;
}
</style>