<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container position-relative">
          <i class="fa fa-times" aria-hidden="true" @click="$emit('close')"></i>
          <div class="modal-header">
            <h3 class="title-header mb_5 mt-1">Login</h3>
          </div>
          <div class="modal-body">
            <form
              action="#"
              method="post"
              class="mt-2"
              @submit.prevent="handleLogin()"
            >
              <div class="form-group">
                <input
                  name="phone_number"
                  class="form__input bordered mb-1"
                  type="text"
                  v-model="fields.phone"
                  placeholder="Phone Number"
                  @keypress="$globalFunc.onlyNumber"
                  maxlength="11"
                  autofocus=""
                />
              </div>
              <div class="form-group">
                <div class="password-input">
                  <input
                    :type="[reveal ? 'text' : 'password']"
                    v-model="fields.password"
                    class="form__input bordered"
                    placeholder="Password"
                  />
                  <i
                    :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']"
                    @click.prevent="revealPassword"
                  ></i>
                </div>
              </div>
              <div class="form-group mt-1">
                <a class="small font-2" href="#" @click.prevent="$store.dispatch('handleModal', 'forgotPassword')">Forgot Password?</a>
              </div>
              <div class="form-group">
                <button class="btn btn-standard mt-1 w-100" :disabled="loading">
                  {{ loading ? "Please wait" : "Confirm" }}
                </button>
              </div>
            </form>
            <p class="small mt-2 text-center">
              Don't have an account?
              <a
                href="#"
                @click.prevent="$store.dispatch('handleModal', 'register')"
                >Register</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AuthHelpers from "../../functions/actions/AuthRequest";
export default {
  name: "LoginModal",
  computed: {
    ...mapState(["loading", "isUserLoggedIn"]),
    ...mapGetters(["isMerchant", "isMerchantActive"])
  },
  beforeMount() {
    if (this.isUserLoggedIn) {
      this.$store.dispatch("handleModal", "login");
    }
  },
  data() {
    return {
      reveal: false,
      fields: {
        phone: "",
        password: "",
      },
    };
  },
  methods: {
    revealPassword() {
      this.reveal = !this.reveal;
    },
    async handleLogin() {
      if (this.validateRequest()) {
        this.reveal = false;
        const response = await AuthHelpers.loginRequest(this.fields);
        if (response.status) {
          this.showAlert("Login success", response.message, "success");
          this.$store.dispatch("handleModal", "login");
          if (this.isMerchant && this.isMerchantActive) {
            this.$router.push({ name: "DashboardHome" });
          } else {
            this.$router.push({ name: "SellElectricity" });
          }
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    validateRequest() {
      if (this.fields.phone) {
        if (this.fields.password) {
          return true;
        } else {
          this.showAlert(
            "Validation Error",
            "Kindly provide your password",
            "warn"
          );
        }
      } else {
        this.showAlert(
          "Validation Error",
          "Kindly provide your phone number",
          "warn"
        );
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
</style>