<template>
  <ul id="progress" v-if="isStepActive" :style="{ 'position': correctHeader }">
    <li class="first" :class="[ currentStep >= 1 && 'active']">1. Order Electricity</li>
    <li class="" :class="[ currentStep >= 2 && 'active']">2. Confirm & make payment</li>
    <li class="last" :class="[ currentStep >= 3 && 'active']">3. Complete Purchase</li>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "ProgressStatus",
  computed: {
    ...mapGetters(['currentStep', 'isStepActive']),
    servicesRoute() {
      return this.$route.path.split("/")[1];
    },
    correctHeader () {
      return this.servicesRoute === 'purchase' ? 'sticky' : 'fixed';
    }
  }
};
</script>
<style scoped>
</style>