<template>
  <div class="fixed-menu" :class="[isOpen ? 'open' : '']">
    <div class="fixed-menu__header">
      <router-link to="/" class="logo logo--color">
        <div class="logo__img"></div>
      </router-link>

      <div class="btn-close" @click.prevent="$store.dispatch('closeSideMenu')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 47.971 47.971"
          style="enable-background: new 0 0 47.971 47.971"
          xml:space="preserve"
          width="512px"
          height="512px"
        >
          <path
            d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
            fill="#006DF0"
          />
        </svg>
      </div>
    </div>

    <div class="fixed-menu__content">
      <ul class="mob-menu">
        <li class="mob-menu__item">
          <a href="#" @click.prevent="handleRouteChange('/pages/services')" class="mob-menu__link">
            Services
          </a>
        </li>
        <li class="mob-menu__item">
          <a href="#" @click.prevent="handleRouteChange('/pages/about')" class="mob-menu__link">
            About
          </a>
        </li>
        <li class="mob-menu__item">
          <a href="#" class="mob-menu__link" onclick="event.preventDefault()">Blog</a>
        </li>
        <li class="mob-menu__item">
          <a href="#" @click.prevent="handleRouteChange('/pages/contact')" class="mob-menu__link">
            Contact us
          </a>
        </li>
      </ul>

      <div class="btn-wrap" v-if="isUserLoggedIn">
       <a href="#" class="btn-sign-in" @click.prevent="handleDashboardLink()">
          Dashboard
        </a>
        <a href="#" class="btn-sign-up" @click.prevent="logoutUser()">Logout</a>
      </div>
      <div class="btn-wrap" v-else>
        <a href="#" class="btn-sign-in" @click.prevent="handleAuthRoute('login')">Login</a>
        <a href="#" class="btn-sign-in" @click.prevent="handleAuthRoute('register')" style="background-color: #f15927">Register</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AuthHelpers from "../../functions/actions/AuthRequest";
export default {
  name: "FixedHeader",
  computed: {
    ...mapState(['user', 'isUserLoggedIn', 'isOpen']),
    ...mapGetters(["isMerchant"]),
  },
  methods: {
    handleRouteChange(path) {
      if (path !== "#") {
        this.$store.dispatch('closeSideMenu');
        this.$router.push({ path });
      }
    },
    handleAuthRoute(type) {
      this.$store.dispatch('closeSideMenu');
      if (type === "login") {
        this.$store.dispatch('handleModal', 'login')
      } else {
        this.$store.dispatch('handleModal', 'register')
      }
    },
    handleDashboardLink() {
      this.$store.dispatch('closeSideMenu');
      if (this.isUserLoggedIn) {
        if (this.isMerchant) {
          this.$router.push({ name: "DashboardHome" });
        } else {
          this.$router.push({ name: "SellElectricity" });
        }
      }
    },
    async logoutUser() {
      const response = await AuthHelpers.logoutRequest();
      if (response.status) {
        // redirect user to homepage
      } else {
        this.showAlert("Error occured", response.message, "error");
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  }
};
</script>

<style scoped>
</style>
