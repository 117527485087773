<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container position-relative">
          <i class="fa fa-times" aria-hidden="true" @click="$emit('close')"></i>
          <div class="modal-header">
            <h3 class="title-header mb_5 mt-1">Register</h3>
          </div>
          <div class="modal-body">
            <form action="#" method="post" class="mt-2" @submit.prevent="handleRegister()">
              <div class="form-group">
                <input name="fullname" class="form__input bordered mb-1" type="text" placeholder="Full Name" autofocus="" v-model="fields.name"/>
              </div>
              <div class="form-group">
                <input name="phone_number" class="form__input bordered mb-1" @keypress="$globalFunc.onlyNumber" type="text" placeholder="Phone Number" maxlength="11" v-model="fields.phone" />
              </div>
              <div class="form-group">
                <input name="email" class="form__input bordered mb-1" type="email" placeholder="Email Address" v-model="fields.email" />
              </div>
              <div class="form-group">
                <input name="bvn" class="form__input bordered mb-1" type="bvn" placeholder="Enter BVN" v-model="fields.bvn" />
              </div>
              <div class="form-group">
                <div class="password-input">
                  <input :type="[reveal ? 'text' : 'password']" class="form__input bordered" :class="[fields.password.length > 0 ? 'mb_5' : 'mb-1']" placeholder="Password" v-model="fields.password" />
                  <i :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']" @click.prevent="revealPassword"></i>
                </div>
              </div>
              <div class="form-group" :class="[{ 'mb-1': fields.password.length > 0 }]" v-if="fields.password.length > 0">
                <p class="small m-0" style="font-size: 12px;line-height: 16px;color: #929292;">Password must contains at least one letter, one digit and one special character (!$#@)</p>
              </div>
              <div class="form-group">
                <div class="password-input">
                  <input :type="[reveal ? 'text' : 'password']" class="form__input bordered" placeholder="Confirm Password" v-model="fields.password_confirmation" />
                  <i :class="[reveal ? 'fas fa-eye-slash' : 'fas fa-eye']" @click.prevent="revealPassword"></i>
                  <span class="error" v-if="fields.password_confirmation.length > 0  && !passwordMatch">Confirm password doesn't match</span>
                </div>
              </div>
              <div class="form-group mt-1 mb-1">
                <input type="checkbox" v-model="terms">
                <label class="d-flex align-items-center" style="font-size: 12px">
                  <span class="custom-checkbox"></span> By clicking on the button below, you agree to our <router-link class="font-2" @click="$emit('close')" to="/pages/terms-of-service">Terms of Use</router-link> and <router-link class="font-2" @click="$emit('close')" to="/pages/privacy-policy">Privacy Policy</router-link>
                </label>
              </div>
              <div class="form-group mb-1">
                <button class="btn btn-standard w-100" type="submit" :disabled="loading">
                  {{ loading ? "Please wait" : "Confirm" }}
                </button>
              </div>
            </form>
            <p class="small mt-2 text-center">
              Already have an account?
              <a href="#" @click.prevent="$store.dispatch('handleModal', 'login')">Login</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AuthHelpers from "../../functions/actions/AuthRequest";
export default {
  name: "RegisterModal",
  computed: {
    ...mapState(["loading", "isUserLoggedIn"]),
    ...mapGetters(["isMerchant"]),
    passwordMatch() {
      return this.fields.password_confirmation === this.fields.password
    }
  },
  beforeMount() {
    if (this.isUserLoggedIn) {
      this.$store.dispatch("handleModal", "login");
    }
  },
  data() {
    return {
      reveal: false,
      fields: {
        name: "",
        phone: "",
        email: "",
        bvn: "",
        password: "",
        password_confirmation: "",
      },
      terms: false,
    };
  },
  methods: {
    revealPassword() {
      this.reveal = !this.reveal;
    },
    async handleRegister() {
      if (this.validateRequest()) {
        this.reveal = false;
        const response = await AuthHelpers.registerRequest(this.fields);
        if (response.status) {
          this.showAlert("Registration success", response.message, "success");
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    validateRequest() {
      if (this.fields.name) {
        if (this.fields.email) {
          if (this.fields.phone) {
            if (this.fields.phone.length == 11) {
              if (this.fields.bvn) {
                if (this.fields.password) {
                  if (this.fields.password === this.fields.password_confirmation) {
                    if (this.terms) {
                      return true;
                    } else {
                      this.showAlert(
                        "Validation Error",
                        "You have to agree to our Terms and Conditions",
                        "error"
                      );
                    }
                  } else {
                    this.showAlert(
                      "Validation Error",
                      "Password confirmation doesn't match",
                      "error"
                    );
                  }
                } else {
                  this.showAlert(
                    "Validation Error",
                    "Kindly provide your password",
                    "error"
                  );
                }
              } else {
                this.showAlert(
                  "Validation Error",
                  "Kindly provide your BVN",
                  "error"
                );
              }
            } else {
              this.showAlert(
                "Validation Error",
                "Phone number must be 11 digits",
                "error"
              );
            }
          } else {
            this.showAlert(
              "Validation Error",
              "Kindly provide your phone number",
              "error"
            );
          }
        } else {
          this.showAlert(
            "Validation Error",
            "Kindly provide your email address",
            "error"
          );
        }
      } else {
        this.showAlert(
          "Validation Error",
          "Kindly provide your full name",
          "error"
        );
      }
    },
    showAlert(title, text, type) {
      this.$notify({
        title,
        text,
        type: type ? type : "warn",
      });
    },
  },
};
</script>
<style scoped>
.password-input i, .select-input i {
  position: absolute;
  color: #000;
  right: 5%;
  top: 50%;
  transform: translate(0%, -90%);
  font-size: 1.2rem;
  cursor: pointer;
}
[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
.custom-checkbox {
  min-width: 1.2rem;
  min-height: 1.2rem;
  margin-right: 0.75em;
  border: 2px solid #28aa63;
  display: inline-block;
}
[type="checkbox"]:checked+label .custom-checkbox {
  border-color: #28aa63;
  background: #28aa63;
  box-shadow: inset 0 0 0 2px white;
}
</style>