<template>
  <aside
    class="sidebar mCustomScrollbar _mCS_1 mCS-autoHide"
    :class="[{ 'collapsed': collapsed }]"
    style="overflow: visible"
  >
    <div
      id="mCSB_1"
      class="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
      style="max-height: none"
      tabindex="0"
    >
      <div
        id="mCSB_1_container"
        class="mCSB_container"
        style="position: relative; left: 0px; top: 0px"
        dir="ltr"
      >
        <div class="sidebar__menu-group">
          <div class="wallet-info" v-if="!collapsed && (isUserActive && !isMerchantActive)">
            <p class="wallet-title">Wallet Balance</p>
            <p class="wallet-balance">&#8358;{{ walletBalance ? $globalFunc.currency(walletBalance) : '0.00' }}</p>
          </div>
          <ul class="sidebar_nav">
            <li v-for="(route, index) in displayedRoutes" :key="index">
              <router-link :to="route.path" :class="[{'active' : isActive(route.path)}]" @click.prevent="handleRouteChange(route.path)">
                <img :src="getImage(route.icon, route.path)" class="img-fluid">
                <span class="menu-text ml-1" v-if="route.icon === 'purchase'">
                  {{  isMerchantActive ? `Sell ` : `Buy ` }} {{ route.name}}
                </span>
                <span class="menu-text ml-1" v-else>
                  {{ route.name}}
                  <span class="badge badge-pill badge-warning" v-if="route.coming_soon">Coming soon</span>
                </span>
              </router-link>
            </li>
            <li>
              <router-link to="#" @click.prevent="logoutUser()">
                <img src="@/assets/buyunits/icons/dashboard/logout-account.png" class="img-fluid">
                <span class="menu-text ml-1">
                  Logout
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      id="mCSB_1_scrollbar_vertical"
      class="
        mCSB_scrollTools mCSB_1_scrollbar
        mCS-minimal-dark
        mCSB_scrollTools_vertical
      "
      style="display: block"
    >
      <div class="mCSB_draggerContainer">
        <div
          id="mCSB_1_dragger_vertical"
          class="mCSB_dragger"
          style="
            position: absolute;
            min-height: 50px;
            display: block;
            height: 155px;
            max-height: 559px;
            top: 0px;
          "
        >
          <div class="mCSB_dragger_bar" style="line-height: 50px"></div>
          <div class="mCSB_draggerRail"></div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import AuthHelpers from "@/functions/actions/AuthRequest";
export default {
  name: "SidebarComponent",
  computed: {
    ...mapState(["collapsed"]),
    ...mapGetters(["isMerchant", "isMerchantActive", "walletBalance", "isUserActive"]),
    displayedRoutes() {
      if (this.isMerchant && this.isMerchantActive) {
        return this.routes.filter(route => (route.category.indexOf("merchant") !== -1 || route.category.indexOf("user") !== -1) && !route.isUser);
      }
      return this.routes.filter(route => route.category.indexOf("user") !== -1);
    },
  },
  data() {
    return {
      routes: [
        {
          path: "/dashboard/home",
          name: "Dashboard",
          icon: "dashboard",
          category: ["merchant"],
        },
        {
          path: "/purchase/retail",
          name: "Electricity",
          icon: "purchase",
          category: ["user"],
        },
        {
          path: "/wallet/index",
          name: "Wallet",
          icon: "wallet",
          category: ["user", "merchant"],
        },
        {
          path: "/transactions/index",
          name: "Transaction History",
          icon: "transaction-history",
          category: ["user", "merchant"],
        },
        {
          path: "/meter-numbers/index",
          name: "Your Meter Numbers",
          icon: "meter-numbers",
          category: ["user", "merchant"],
        },
        // {
        //   path: "/saved-cards/index",
        //   name: "Saved Cards",
        //   icon: "saved-cards",
        //   category: ["user", "merchant"],
        // },
        {
          path: "/unit-calculator/index",
          name: "Unit Calculator",
          icon: "unit-calculator",
          category: ["user", "merchant"],
          coming_soon: true,
        },
        {
          path: "/request-utility-bill/index",
          name: "Request Utility Bill",
          icon: "request-utility-bill",
          category: ["user", "merchant"],
          coming_soon: true,
        },
        {
          path: "/become-merchant/index",
          name: "Become A Merchant",
          icon: "become-merchant",
          category: ["user"],
          isUser: true,
        },
        {
          path: "/account-settings/index",
          name: "Account Settings",
          icon: "account-settings",
          category: ["user", "merchant"],
        },
        {
          path: "/account-settings/api-keys",
          name: "API Keys",
          icon: "account-settings",
          category: ["merchant"],
        },
      ]
    };
  },
  methods: {
    handleRouteChange(path) {
      this.$router.push({ path });
      if (window.innerWidth < 1151) {
        if (this.collapsed) {
          this.$store.dispatch('toggleSidebar', 0);
        } else {
          this.$store.dispatch('toggleSidebar', 1);
        }
      }
    },
    isActive(currentRoute) {
      return this.$route.path.split("/")[1] === currentRoute.split("/")[1];
    },
    async logoutUser() {
      if (confirm("Are you sure you want to logout?")) {
        const response = await AuthHelpers.logoutRequest();
        if (response.status) {
          // redirect user to homepage
        } else {
          this.showAlert("Error occured", response.message, "error");
        }
      }
    },
    getImage(icon, path) {
      if (this.isActive(path)) {
        return require(`@/assets/buyunits/icons/dashboard/${icon}-active.svg`);
      } else {
        return require(`@/assets/buyunits/icons/dashboard/${icon}.svg`);
      }
    },
  }
};
</script>
<style scoped>
.wallet-info {
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 1.5rem 1rem;
  margin: 2rem 2rem 0;
  text-align: center;
}
.wallet-info .wallet-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #505050;
}
.wallet-info .wallet-balance {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #333333;
  margin-top: 0.3rem;
}
</style>