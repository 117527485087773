import Api from "@/services/Api";

export default {
  getWalletHistory(page) {
    return Api().get(`/users/wallet/history?page=${page}`);
  },
  getWalletBalance() {
    return Api().get(`/users/wallet/balance`);
  },
  fundUserWallet(payload) {
    return Api().post(`/users/wallet/fund`, payload);
  },
  generatePaymentCode(payload) {
    return Api().post(`/users/wallet/generate/code`, payload);
  },
};