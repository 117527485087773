import Api from "@/services/Api";

export default {
  updateProfile(payload) {
    return Api().put(`/users/profile`, payload);
  },
  changePassword(payload) {
    return Api().post(`/users/settings/password`, payload);
  },
  resendPhoneToken() {
    return Api().get(`/auth/resend/token`);
  },
  verifyPhoneToken(payload) {
    return Api().post(`/auth/verify/token`, payload);
  },
  getIntegrationKey() {
    return Api().get(`/users/api-key`);
  },
};