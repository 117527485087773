<template>
    <section class="app section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12" >
                    <div class="block-header block-header--animated">
                        <h2 class="title title--medium text-white text-center title--30">
                            Enjoy Amazing Features On BuyUnits.ng
                        </h2>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-standard w-45 btn-standard-white m-0" @click.prevent="$store.dispatch('handleModal', 'register')">
                            Register Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: "RegisterOption",
    data() {
        return {}
    },
}
</script>
<style scoped>
.app__iphone {
    position: absolute;
    right: 15%;
    z-index: 2;
    top: 15px;
}
.app.section {
    border-radius: 20px;
    background-image: url(@/assets/buyunits/star-light.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: auto 20px;
    padding: 100px 0px;
}
@media (max-width: 991.98px) {
    .app__iphone {
        right: 15px;
        bottom: -35px;
    }
}
</style>